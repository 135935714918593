import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { useProvidePDLInstantContentPageContext } from "adminComponents/hooks/useProvidePDLInstantContentPageContext";
import { QuestionsTab as PracticeSetDetailQuestionsTab } from "adminComponents/screens/ClassroomPracticeSetReportScreen/components/QuestionsTab";
import { pxToRem } from "adminComponents/utils";
import { practiceSetAndResultsToString } from "adminComponents/utils/pdlPageContext";
import { usePageTrack } from "lib/contexts/analytics";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchClassroomUsersResponse } from "links/lib/features/classrooms/useFetchClassroomUsers";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { AnalyticsPage, IPracticeSet } from "links/lib/types";
import { useStudentsModal } from "screens/TeacherClassrooms/PracticeSetDetail/hooks/useStudentsModal";
import { useQuestionsData } from "screens/TeacherClassrooms/shared/hooks/useQuestionsData";

interface IQuestionsTabProps {
  practiceSet?: IPracticeSet;
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  classroomStudentsData?: IFetchClassroomUsersResponse;
  classroomId: string;
  practiceSetId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

export const QuestionsTab: React.FC<IQuestionsTabProps> = ({
  classroomId,
  practiceSet,
  practiceSetId,
  classroomPracticeSetResponses,
  practiceSetItemsData,
  classroomStudentsData,
  assignmentId,
  practiceSetSessionId,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetDetail_Questions
  );

  const { questionsData, isLoading } = useQuestionsData({
    classroomId,
    practiceSetId,
    classroomPracticeSetResponses,
    practiceSetItemsData,
    assignmentId,
    practiceSetSessionId,
  });

  const { drawingResultsModal, studentsModal, handleViewStudentResponses } =
    useStudentsModal({
      classroomPracticeSetResponses,
      classroomStudentsData,
    });

  const studentsCount = useMemo(() => {
    const studentIdsSet = new Set<string>();
    classroomPracticeSetResponses?.user_responses.forEach((r) => {
      studentIdsSet.add(r.user_id);
    });
    return studentIdsSet.size;
  }, [classroomPracticeSetResponses?.user_responses]);

  const pdlInstantContentPageContext = useMemo(
    () =>
      practiceSet
        ? practiceSetAndResultsToString(practiceSet, questionsData)
        : undefined,
    [practiceSet, questionsData]
  );
  useProvidePDLInstantContentPageContext(
    "teacher-classroom-practice-set-detail-questions-tab",
    pdlInstantContentPageContext
  );

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          <PracticeSetDetailQuestionsTab
            questionsData={questionsData}
            handleViewStudentResponses={handleViewStudentResponses}
            studentsCount={studentsCount}
          />
          {studentsModal}
          {drawingResultsModal}
        </>
      )}
    </>
  );
};
