import { Portal } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenRiveTakeover } from "../FullscreenRiveTakover";
import ZoneUnlockRiveSrc from "./resource/zones_unlocked.riv";

export interface ISeason5MapZoneUnlock {
  show: boolean;
  zoneCount: number;
  handleComplete: () => void;
}

export const Season5MapZoneUnlock: React.FC<ISeason5MapZoneUnlock> = ({
  show,
  handleComplete,
  zoneCount,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.S5ZoneUnlock);
  }, [play]);

  const riveAnimationData = useMemo(() => {
    if (zoneCount < 3) {
      return {
        stateMachines: ["Zone2_unlocked"],
        animations: [
          "gps bounce",
          "Z2 screen anim purple",
          "Zone2 unlocked text",
        ],
      };
    }

    return {
      stateMachines: ["Zone3_unlocked"],
      animations: [
        "gps bounce",
        "Z3 screen anim purple",
        "Zone3 unlocked text",
      ],
    };
  }, [zoneCount]);

  return (
    <Portal>
      <FullscreenRiveTakeover
        handleEnd={handleComplete}
        handlePlay={handlePlay}
        show={show}
        bgColor="#FFDA00"
        src={ZoneUnlockRiveSrc}
        lengthInMillis={12000}
        stateMachines={riveAnimationData.stateMachines}
        animations={riveAnimationData.animations}
      />
    </Portal>
  );
};
