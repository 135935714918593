import moment from "moment";
import { useMemo } from "react";

import { premiumExtendHATUsersDeadline } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import useFetchHATStats from "links/lib/features/users/useFetchHATStats";
import { UserRole } from "links/lib/types";

// this hook is specific to a feature allowing orgless HATs to extend their trial
// This feature should no longer render past May 2025 (premiumExtendHATUsersDeadline)
export const useCanUserExtendTrial = (): boolean => {
  const { isFeatureEnabled, hasNoPremiumAccess, authLicense, authUser } =
    useAuth();

  const getIsPotentiallyEligible = () => {
    const isInOrganization =
      authUser?.organization_id && authUser?.organization_id !== "0";
    if (isInOrganization) {
      return false;
    }

    const isTrialExpired = hasNoPremiumAccess && !!authLicense?.is_trial;
    if (!isTrialExpired) {
      return false;
    }

    if (authUser?.role !== UserRole.Teacher) {
      return false;
    }

    const isWithinFeatureDeadline =
      moment(premiumExtendHATUsersDeadline).diff(moment(), "days") >= 0;
    if (!isWithinFeatureDeadline) {
      return false;
    }

    return true;
  };

  const isPotentiallyEligible = getIsPotentiallyEligible();

  const hatStatsResp = useFetchHATStats({
    userId: authUser?.id,
    disabled: !isPotentiallyEligible,
  });
  const isHAT = useMemo(
    () =>
      hatStatsResp &&
      ((hatStatsResp.data?.hat_stats?.assignment_count ?? 0) > 5 ||
        (hatStatsResp.data?.hat_stats.session_count ?? 0) > 5),
    [hatStatsResp]
  );
  if (!isPotentiallyEligible || !isHAT) {
    return false;
  }

  return isFeatureEnabled("playtime.enable_hat_premium_extend");
};
