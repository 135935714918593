import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { IForm } from "adminComponents/organisms/TeacherAccountInformationForm";
import { TeacherAccountScreen } from "adminComponents/screens/TeacherAccountScreen";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { TeacherTitleOptions } from "links/lib/constants";
import { useMutateAuthUser } from "links/lib/features/account";
import { useAuth } from "links/lib/features/auth";
import { useFetchSubjectsOnce } from "links/lib/features/subjects";
import { AnalyticsEvent, AnalyticsPage, UserRole } from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export const TeacherAccount: React.FC = () => {
  const {
    authUser,
    authSharingSettings,
    updateUserRole,
    isAuthLoading,
    isFeatureEnabled,
  } = useAuth();
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherAccountContainer",
  });
  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_Account);

  const {
    isLoading: isMutateLoading,
    isSuccess,
    mutate,
    error,
  } = useMutateAuthUser();
  useEffect(() => {
    if (!isSuccess) return;
    showToast(t("successToast"));
  }, [isSuccess, showToast, t]);
  useErrorToast(error);

  const subjectsQuery = useFetchSubjectsOnce();
  useMount(() => {
    subjectsQuery.execute({
      limit: 50,
      country: authUser?.country || "",
    });
  });

  const teacherGradeLevelIds = authUser?.teacher_grade_levels?.map(
    (teacherGradeLevel) => teacherGradeLevel.grade_level_id
  );

  const teacherSubjectIds = authUser?.teacher_subjects?.map(
    (teacherSubject) => teacherSubject.subject_id
  );

  const subjects = (subjectsQuery.data?.subjects || []).filter(
    (subject) => subject.parent_id === "0"
  );

  const onChange = (form: IForm) => {
    if (form.country === authUser?.country) return;

    subjectsQuery.execute({
      limit: 50,
      country: form.country,
    });
  };

  const isLoading = isMutateLoading || subjectsQuery.isLoading || isAuthLoading;

  const debouncedUpdateUserRole = useMemo(
    () =>
      debounce(
        () => {
          if (authUser?.id) {
            updateUserRole(authUser?.id, UserRole.Student);
          }
        },
        500,
        { leading: true, trailing: false }
      ),
    [updateUserRole, authUser?.id]
  );

  const onChangeToStudent = useCallback(() => {
    debouncedUpdateUserRole();
  }, [debouncedUpdateUserRole]);

  const onSubmit = (form: IForm) => {
    trackEvent(AnalyticsEvent.TeacherDashboard_Account_SubmitForm, {});

    mutate({
      teacher_preferred_name: form.nickname,
      teacher_title: form.title,
      teacher_school_or_district_name: form.schoolOrDistrictName,
      teacher_school_or_district_postal_code: form.schoolOrDistrictPostalCode,
      timezone_locale: form.timezone.replaceAll(" ", "_"),
      grade_level_ids: form.grades,
      subject_ids: form.subjects,
      language: form.language,
      country: form.country,
      region: form.state,
    });
  };

  // authUser is validated in TeacherDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  return (
    <TeacherAccountScreen
      onChangeToStudent={
        isFeatureEnabled("playtime.enable_teacher_to_student_role_change")
          ? onChangeToStudent
          : undefined
      }
      navigationData={navigationData}
      teacher={authUser}
      isLoading={isLoading}
      handleSubmit={onSubmit}
      handleChange={onChange}
      grades={navigationData.gradeLevels}
      subjects={subjects}
      titles={TeacherTitleOptions}
      initialValues={{
        acceptedAgreementIds: [],
        country: authUser.country,
        grades: teacherGradeLevelIds,
        language: authUser.language,
        nickname: authUser.teacher_preferred_name,
        state: authUser.region,
        subjects: teacherSubjectIds,
        timezone: authUser.timezone_locale.replaceAll("_", " "),
        title: authUser.teacher_title,
        sharingVisibility: authSharingSettings?.visibility,
        schoolOrDistrictName: authUser.teacher_school_or_district_name,
        schoolOrDistrictPostalCode:
          authUser.teacher_school_or_district_postal_code,
      }}
      isFormLoading={isMutateLoading}
      linkedAccount={"Google"}
      externalAccountLink={""}
    />
  );
};
