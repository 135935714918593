import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  LocationInfo,
  SeasonMap,
  SeasonMapLocation,
  SeasonMapPin,
  SeasonMapZone,
} from "sharedComponents/molecules/SeasonMap";
import { ISeasonMapPannerChildProps } from "sharedComponents/molecules/SeasonMapPanner";

import BackdropImage from "./resource/backdrop.svg";
import LandmarksImage from "./resource/landmarks.svg";
// the following DotsImage import is helpful for aligning the location dots on the map
// import DotsImage from "./resource/location_dots.svg";
import BlusteryBayPreviewImage from "./resource/locations/location_preview_s1_blustery_bay.svg";
import PearyPrairiePreviewImage from "./resource/locations/location_preview_s2_peary_prairie.svg";
import DazzlingDunesPreviewImage from "./resource/locations/location_preview_s3_dazzling_dunes.svg";
import BrilliantBeachPreviewImage from "./resource/locations/location_preview_s4_brilliant_beach.svg";
import MammothMountainPreviewImage from "./resource/locations/location_preview_s5_mammoth_mountain.svg";
import PearopolisPreviewImage from "./resource/locations/location_preview_s6_pearopolis.svg";
import { ReactComponent as Zone1PathSVG } from "./resource/zone1-path.svg";
import { ReactComponent as Zone2PathSVG } from "./resource/zone2-path.svg";
import { ReactComponent as Zone3PathSVG } from "./resource/zone3-path.svg";

export interface ISeason5MapProps extends ISeasonMapPannerChildProps {
  // zone ids for unlocked zones
  unlockedZoneIds: Array<string>;
  // the id of the user's current location on map
  currentLocationId?: string;
  // the profile image of the user to show in the map pin
  userImg?: string;
  // user progression for each map location
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  // handler for when a location is selected
  handleLocationSelect?: (info: LocationInfo) => void;
}

export interface IZoneData {
  zoneId: string;
  offset: { top: number; left: number };
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  width: number;
  height: number;
  locations: Array<ILocationData>;
}

export interface ILocationData {
  offset: { top: number; left: number };
  locationId: string;
  previewImageUrl: string;
}

export const mapSize = {
  width: 3840,
  height: 2061,
};

export const mapData: Array<IZoneData> = [
  // Zone 1
  {
    zoneId: "13",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone1PathSVG />,
    pathColor: "#FA553A",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 960, left: 3200 }, //checked
        locationId: "37",
        previewImageUrl: BlusteryBayPreviewImage,
      },
      {
        offset: { top: 1750, left: 2825 }, //checked
        locationId: "38",
        previewImageUrl: PearyPrairiePreviewImage,
      },
    ],
  },

  // Zone 2
  {
    zoneId: "14",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone2PathSVG />,
    pathColor: "#00A3DE",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1710, left: 1830 }, //checked
        locationId: "39",
        previewImageUrl: DazzlingDunesPreviewImage,
      },
      {
        offset: { top: 1445, left: 605 }, //checked
        locationId: "40",
        previewImageUrl: BrilliantBeachPreviewImage,
      },
    ],
  },

  // Zone 3
  {
    zoneId: "15",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone3PathSVG />,
    pathColor: "#E961B1",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 385, left: 760 }, //checked
        locationId: "41",
        previewImageUrl: MammothMountainPreviewImage,
      },
      {
        offset: { top: 420, left: 2320 }, //checked
        locationId: "42",
        previewImageUrl: PearopolisPreviewImage,
      },
    ],
  },
];

export const Season5Map: React.FC<ISeason5MapProps> = ({
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
  unlockedZoneIds,
  userImg,
  currentLocationId,
  locationsProgress,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season5.locations",
    useSuspense: false,
  });

  // merge map data with translations, location progress, and zone locks
  const combinedMapData = useMemo(() => {
    return mapData.map((zoneData) => {
      return {
        ...zoneData,
        isLocked: !unlockedZoneIds.some((z) => z === zoneData.zoneId),
        locations: zoneData.locations.map((l) => ({
          ...l,
          // translate name
          name: t(`loc${l.locationId}.name`),
          // translate description
          description: t(`loc${l.locationId}.description`),
          // update progress for location
          progress: locationsProgress.find(
            (lp) => lp.locationId === l.locationId
          ) ?? { totalSpaces: 1, spacesCollected: 0, locationId: l.locationId },
        })),
      };
    });
  }, [t, unlockedZoneIds, locationsProgress]);

  return (
    <SeasonMap
      width={mapSize.width}
      height={mapSize.height}
      backdropImage={BackdropImage}
      landmarksImage={LandmarksImage}
      // use DotsImage to position location offsets above
      // landmarksImage={DotsImage}
      scale={scale}
      handleLocationFocus={handleLocationFocus}
      handleLocationSelect={handleLocationSelect}
    >
      {combinedMapData.map((zoneData) => (
        <SeasonMapZone
          key={zoneData.zoneId}
          offset={zoneData.offset}
          pathSVG={zoneData.pathSVG}
          pathColor={zoneData.pathColor}
          width={zoneData.width}
          height={zoneData.height}
          isLocked={zoneData.isLocked}
        >
          {zoneData.locations.map((locationData) => {
            return (
              <SeasonMapLocation
                key={locationData.locationId}
                name={locationData.name}
                description={locationData.description}
                offset={locationData.offset}
                locationId={locationData.locationId}
                progress={locationData.progress}
                previewImageUrl={locationData.previewImageUrl}
              >
                {currentLocationId === locationData.locationId ? (
                  <SeasonMapPin userImg={userImg} />
                ) : (
                  <></>
                )}
              </SeasonMapLocation>
            );
          })}
        </SeasonMapZone>
      ))}
    </SeasonMap>
  );
};
