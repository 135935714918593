import React from "react";
import { useTranslation } from "react-i18next";

import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import useExtendHATUserIndividualTrial from "links/lib/features/users/useExtendHATUserIndividualTrial";

import { ConfirmModal } from "../ConfirmModal";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  onConfirm: () => void;
}

export const ExtendHATPremiumModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  onConfirm,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const showToast = useShowToast();
  const { refetchUser } = useAuth();

  const onSuccess = () => {
    showToast(t("extendHATPremium.extendSuccess"));
    refetchUser();
  };

  const { mutate: extendPremium, error } = useExtendHATUserIndividualTrial({
    onSuccess,
  });
  useErrorToast(error, t("extendHATPremium.extendError"));

  const _handleClose = (confirmed?: boolean) => {
    if (confirmed) {
      extendPremium();
      onConfirm();
    }
    handleClose();
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      buttonCancelLabel={t("common.cancel")}
      buttonOKLabel={t("extendHATPremium.confirmLabel")}
      handleClose={_handleClose}
      mobileButtonsDirection="vertical"
      size="2xl"
      description={t("extendHATPremium.modalDescription")}
      title={t("extendHATPremium.modalTitle")}
      variant="adminModalCompactMobile"
    />
  );
};
