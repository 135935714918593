import { Portal } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenRiveTakeover } from "../FullscreenRiveTakover";
import S5StageTransition from "./resource/s5_stage_transition.riv";

export interface ISeason5MapTransition {
  show: boolean;
  handleComplete: () => void;
  currentLocationId?: string;
}

export const Season5MapTransition: React.FC<ISeason5MapTransition> = ({
  show,
  handleComplete,
  currentLocationId,
}) => {
  const { play } = useAudio();

  const riveAnimationData = useMemo(() => {
    switch (currentLocationId) {
      case "39":
      case "40":
        return {
          animations: "move_to_Z2",
          stateMachines: "move_to_Z2",
          bgColor: "#A0F2F2",
          sound: SeasonMapSoundEffect.S5Zone2Transition,
        };
      case "41":
      case "42":
        return {
          animations: "move_to_Z3",
          stateMachines: "move_to_Z3",
          bgColor: "#F9C8E8",
          sound: SeasonMapSoundEffect.S5Zone3Transition,
        };
      default:
        // "37" & "38" and everything else
        return {
          animations: "move_to_Z1",
          stateMachines: "move_to_Z1",
          bgColor: "#FED1C4",
          sound: SeasonMapSoundEffect.S5Zone1Transition,
        };
    }
  }, [currentLocationId]);

  const handlePlay = useCallback(() => {
    play(riveAnimationData.sound);
  }, [play, riveAnimationData]);

  return (
    <Portal>
      <FullscreenRiveTakeover
        handleEnd={handleComplete}
        handlePlay={handlePlay}
        show={show}
        bgColor={riveAnimationData.bgColor}
        src={S5StageTransition}
        lengthInMillis={3500}
        stateMachines={riveAnimationData.stateMachines}
        animations={riveAnimationData.animations}
      />
    </Portal>
  );
};
