import { Box, Center, Link } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useLocalStorage } from "react-use";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

interface IDismissableBannerProps {
  title: string;
  subtitle?: string;
  linkText?: string;
  linkTo?: string;
  linkOnClick?: () => void;
  localStorageDismissedStateKey: string;
  stayDismissedForever?: boolean;
}

const DISMISS_FOR_DAYS = 2;

export const DismissableBanner: React.FC<IDismissableBannerProps> = ({
  title,
  subtitle,
  linkText,
  linkTo,
  linkOnClick,
  localStorageDismissedStateKey,
  stayDismissedForever = false,
}) => {
  const [lastDismissed, setLastDismissed] = useLocalStorage<string>(
    localStorageDismissedStateKey
  );

  const showBanner = useMemo(() => {
    if (stayDismissedForever && lastDismissed) {
      return false;
    }

    const lastDeferralMoment = moment(lastDismissed || "invalid");

    if (!lastDeferralMoment.isValid()) {
      return true;
    }

    return lastDeferralMoment.add(DISMISS_FOR_DAYS, "days").isBefore(moment());
  }, [lastDismissed, stayDismissedForever]);

  const handleDefer = useCallback(() => {
    setLastDismissed(new Date().toISOString());
  }, [setLastDismissed]);

  return (
    <>
      {showBanner && (
        <Center
          __css={{
            display: "flex",
            backgroundColor: "primary.golden-light",
            height: "fit-content",
            paddingX: pxToRem(30),
            paddingY: pxToRem(20),
            transition: "opacity 250ms",
            width: "fit-content",
            marginX: "auto",
          }}
        >
          <Icon icon="timer" mr={pxToRem(20)} />
          <Box>
            <Text variant="adminP1Bold" display="inline">
              {title}
            </Text>
            {subtitle && (
              <>
                &nbsp;
                <Text variant="adminP1" display="inline">
                  {subtitle}
                </Text>
              </>
            )}
            &nbsp;
            {linkText && (linkTo || linkOnClick) && (
              <Link
                cursor="pointer"
                target="_blank"
                href={linkTo}
                onClick={linkOnClick}
              >
                <Text
                  variant="adminLinkLarge"
                  display="inline"
                  color="utility.link"
                >
                  {linkText}
                </Text>
              </Link>
            )}
          </Box>
          <Icon
            cursor="pointer"
            icon="close"
            boxSize={pxToRem(18)}
            ml={pxToRem(40)}
            onClick={handleDefer}
          />
        </Center>
      )}
    </>
  );
};
