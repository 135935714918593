import {
  IPracticeSet,
  IPracticeSetItem,
  IPracticeSetItemWithClassroomResults,
  IPracticeSetSessionItemReport,
  QuestionType,
} from "links/lib/types";

export const practiceSetAndItemsToString = (
  practiceSet: IPracticeSet,
  practiceSetItems: IPracticeSetItem[]
): string => {
  const parts = [practiceSetToString(practiceSet)];

  for (let i = 0; i < practiceSetItems.length; i++) {
    const item = practiceSetItems[i];
    parts.push(`\nItem ${i + 1}:`);
    parts.push(practiceSetItemToString(item, 2));
  }

  return parts.join("\n");
};

export const practiceSetAndResultsToString = (
  practiceSet: IPracticeSet,
  results: Partial<IPracticeSetItemWithClassroomResults>[]
): string => {
  const parts = [practiceSetToString(practiceSet)];

  for (let i = 0; i < results.length; i++) {
    const { practiceSetItem: item, classroomResults: result } = results[i];
    if (!item) continue;

    parts.push(`\nItem ${i + 1}:`);
    parts.push(practiceSetItemToString(item, 2));
    if (result) {
      parts.push(practiceSetResultToString(result, 2));
    }
  }

  return parts.join("\n");
};

const practiceSetToString = (practiceSet: IPracticeSet, indent = 0): string => {
  const parts: string[] = [
    `Practice Set Title: ${practiceSet.title}`,
    `Practice Set Description: ${practiceSet.description}`,
  ];

  if (practiceSet.grade_levels.length > 0) {
    parts.push(
      `Practice Set Grade Level: ${practiceSet.grade_levels
        .map((gl) => gl.grade_level.grade_level)
        .join(", ")}`
    );
  }

  if (practiceSet.subjects.length > 0) {
    parts.push(
      `Practice Set Subject: ${practiceSet.subjects
        .map((s) => s.subject.name)
        .join(", ")}`
    );
  }

  return parts.map((s) => " ".repeat(indent) + s).join("\n");
};

const practiceSetItemToString = (
  item: IPracticeSetItem,
  indent = 0
): string => {
  const parts: string[] = [];

  for (let j = 0; j < item.standards.length; j++) {
    const standard = item.standards[j];
    parts.push(
      `- Standard ${j + 1}:`,
      `  - Code: ${standard.label}`,
      `  - Description: ${standard.description}`
    );
  }

  parts.push(`- Question Type: ${item.question_type}`);

  switch (item.question_type) {
    case QuestionType.TermDefinition: {
      parts.push(
        `  - Term: ${item.term_definition?.term}`,
        `  - Definition: ${item.term_definition?.definition}`
      );
      break;
    }
    case QuestionType.MultipleChoice: {
      if (item.multiple_choice?.choices) {
        for (let j = 0; j < item.multiple_choice.choices.length; j++) {
          const choice = item.multiple_choice.choices[j];
          const choiceText = choice.image_url
            ? choice.image_alt_text || "[image]"
            : choice.text;
          const correctText = choice.is_correct ? " (correct)" : "";
          parts.push(`  - Choice ${j + 1}${correctText}: ${choiceText}`);
        }
      }
      break;
    }
    case QuestionType.MultipleSelect: {
      if (item.multiple_select?.choices) {
        for (let j = 0; j < item.multiple_select.choices.length; j++) {
          const choice = item.multiple_select.choices[j];
          const choiceText = choice.image_url
            ? choice.image_alt_text || "[image]"
            : choice.text;
          const correctText = choice.is_correct ? " (correct)" : "";
          parts.push(`  - Choice ${j + 1}${correctText}: ${choiceText}`);
        }
      }
      break;
    }
    case QuestionType.OpenEnded: {
      parts.push(`  - Prompt: ${item.open_ended?.prompt}`);
      break;
    }
    case QuestionType.TextResponse: {
      parts.push(
        `  - Prompt: ${item.text_response?.prompt}`,
        `  - Correct Response: ${item.text_response?.correct_response}`
      );
      break;
    }
    case QuestionType.NumberResponse: {
      parts.push(
        `  - Prompt: ${item.number_response?.prompt}`,
        `  - Correct Response: ${item.number_response?.correct_response}`
      );
      break;
    }
    case QuestionType.Diagram: {
      parts.push(`  - Prompt: ${item.diagram?.prompt}`);
      break;
    }
    case QuestionType.Classify: {
      parts.push(`  - Prompt: ${item.classify?.prompt}`);
      if (item.classify?.categories) {
        for (let j = 0; j < item.classify.categories.length; j++) {
          const category = item.classify.categories[j];
          parts.push(`  - Category ${j + 1}: ${category.text}`);
          for (let k = 0; k < category.choices.length; k++) {
            const choice = category.choices[k];
            const choiceText = choice.image_url
              ? choice.image_alt_text || "[image]"
              : choice.text;
            parts.push(`    - Choice ${k + 1}: ${choiceText}`);
          }
        }
      }
      break;
    }
    case QuestionType.Draw: {
      parts.push(`  - Prompt: ${item.draw?.prompt}`);
      break;
    }
    case QuestionType.Unknown: {
      break;
    }
    default: {
      const _exhaustiveCheck: never = item.question_type;
      console.warn(`Unhandled question type: ${_exhaustiveCheck}`);
      return "";
    }
  }

  return parts.map((s) => " ".repeat(indent) + s).join("\n");
};

const practiceSetResultToString = (
  result: IPracticeSetSessionItemReport,
  indent = 0
): string =>
  [
    `- Classroom Results:`,
    `  - Accuracy: ${Math.round(result.accuracy)}%`,
    `  - Correct Responses: ${result.correct_responses}`,
    `  - Total Responses: ${result.total_responses}`,
  ]
    .map((s) => " ".repeat(indent) + s)
    .join("\n");
