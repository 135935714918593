import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import {
  TeacherLinkedAccountCard,
  IProps as TeacherLinkedAccountCardProps,
} from "adminComponents/molecules/TeacherLinkedAccountCard";
import {
  TeacherAccountInformationForm,
  IProps as TeacherAccountInformationFormProps,
} from "adminComponents/organisms/TeacherAccountInformationForm";
import { TeacherAccountTemplate } from "adminComponents/templates/TeacherAccountTemplate";
import { IUser } from "links/lib/types";

export interface IProps
  extends TeacherLinkedAccountCardProps,
    TeacherAccountInformationFormProps {
  navigationData: INavigationHeaderProps;
  teacher: IUser;
  isLoading: boolean;
  isFormLoading: boolean;
  onChangeToStudent?: () => void;
}

export const TeacherAccountScreen: React.FC<IProps> = ({
  navigationData,
  teacher,
  linkedAccount,
  externalAccountLink,
  grades,
  subjects,
  titles,
  initialValues,
  isLoading,
  isFormLoading,
  handleChange,
  handleSubmit,
  onChangeToStudent,
}) => {
  const styles = useMultiStyleConfig("AdminTeacherAccountScreen", {});

  const linkedAccountData = {
    teacher: teacher,
    linkedAccount: linkedAccount,
    externalAccountLink: externalAccountLink,
  };

  const formData = {
    handleSubmit: handleSubmit,
    handleChange: handleChange,
    grades: grades,
    subjects: subjects,
    titles: titles,
    initialValues: initialValues,
    isLoading: isFormLoading,
  };

  return (
    <TeacherAccountTemplate
      navigationData={navigationData}
      isLoading={isLoading}
    >
      <Flex sx={styles.contentContainer}>
        <TeacherLinkedAccountCard
          onChangeToStudent={onChangeToStudent}
          {...linkedAccountData}
        />
        <TeacherAccountInformationForm {...formData} />
      </Flex>
    </TeacherAccountTemplate>
  );
};
