import { ColorScheme } from "adminComponents";

import {
  CoverImageBGColorSchemeType,
  CoverImageIconType,
  IAvatarAnimation,
  RemixBGColorSchemeType,
  RemixIconType,
} from "./types";

export const productName =
  process.env.REACT_APP_PRODUCT_NAME || "Pear Practice";

export const XPPerLevel = 210;

export const quickPlayStudenRoundReviewInterstitialTimeMs = 6000;

export const localStoreAuthKeyName = "auth";
export const localStoreRecentClassroomIdsKeyName = "recentClassroomIds";
export const localStorePostSessionReturnPath = "postSessionReturnPath";
export const localStoreGracePeriodDeferralKeyName = "lastGracePeriodDeferral";
export const localStoreExtendPremiumDismissedKeyName =
  "lastExtendPremiumDeferral";
export const premiumExtendHATUsersDeadline = "2025-05-31";

export const localStoreClassroomsReportsStudentSortByKeyName =
  "classroomsReportsStudentSortBy";
export const localStoreClassroomsReportsStudentSortAscKeyName =
  "classroomsReportsStudentSortAsc";

// Teacher onboarding checklist
export const localStoreHasCompletedOnboardingKeyName = "hasCompletedOnboarding";
export const localStoreHideOnboardingUntilKeyName = "hideOnboardingUntil";
export const localStoreHasCompletedProfileKeyName = "hasCompletedProfile";
export const localStoreHasInvitedStudentsKeyName = "hasInvitedStudents";
export const localStoreHasExploredSharedLibrariesKeyName =
  "hasExploredSharedLibraries";
export const localStoreHasStartedLivePracticeOrCreatedAssignmentKeyName =
  "hasStartedLivePracticeOrCreatedAssignment";
export const localStoreHideLivePracticeEncouragementUntilKeyName =
  "hideLivePracticeEncouragementUntil";

// commenting out all non-us countries for initial launch
export const countryMap: { [key: string]: string } = {
  // AF: "Afghanistan",
  // AX: "Åland Islands",
  // AL: "Albania",
  // DZ: "Algeria",
  // AS: "American Samoa",
  // AD: "AndorrA",
  // AO: "Angola",
  // AI: "Anguilla",
  // AQ: "Antarctica",
  // AG: "Antigua and Barbuda",
  // AR: "Argentina",
  // AM: "Armenia",
  // AW: "Aruba",
  // AU: "Australia",
  // AT: "Austria",
  // AZ: "Azerbaijan",
  // BS: "Bahamas",
  // BH: "Bahrain",
  // BD: "Bangladesh",
  // BB: "Barbados",
  // BY: "Belarus",
  // BE: "Belgium",
  // BZ: "Belize",
  // BJ: "Benin",
  // BM: "Bermuda",
  // BT: "Bhutan",
  // BO: "Bolivia",
  // BA: "Bosnia and Herzegovina",
  // BW: "Botswana",
  // BV: "Bouvet Island",
  // BR: "Brazil",
  // IO: "British Indian Ocean Territory",
  // BN: "Brunei Darussalam",
  // BG: "Bulgaria",
  // BF: "Burkina Faso",
  // BI: "Burundi",
  // KH: "Cambodia",
  // CM: "Cameroon",
  // CA: "Canada",
  // CV: "Cape Verde",
  // KY: "Cayman Islands",
  // CF: "Central African Republic",
  // TD: "Chad",
  // CL: "Chile",
  // CN: "China",
  // CX: "Christmas Island",
  // CC: "Cocos (Keeling) Islands",
  // CO: "Colombia",
  // KM: "Comoros",
  // CG: "Congo",
  // CD: "Congo, The Democratic Republic of the",
  // CK: "Cook Islands",
  // CR: "Costa Rica",
  // CI: "Cote D'Ivoire",
  // HR: "Croatia",
  // CU: "Cuba",
  // CY: "Cyprus",
  // CZ: "Czech Republic",
  // DK: "Denmark",
  // DJ: "Djibouti",
  // DM: "Dominica",
  // DO: "Dominican Republic",
  // EC: "Ecuador",
  // EG: "Egypt",
  // SV: "El Salvador",
  // GQ: "Equatorial Guinea",
  // ER: "Eritrea",
  // EE: "Estonia",
  // ET: "Ethiopia",
  // FK: "Falkland Islands (Malvinas)",
  // FO: "Faroe Islands",
  // FJ: "Fiji",
  // FI: "Finland",
  // FR: "France",
  // GF: "French Guiana",
  // PF: "French Polynesia",
  // TF: "French Southern Territories",
  // GA: "Gabon",
  // GM: "Gambia",
  // GE: "Georgia",
  // DE: "Germany",
  // GH: "Ghana",
  // GI: "Gibraltar",
  // GR: "Greece",
  // GL: "Greenland",
  // GD: "Grenada",
  // GP: "Guadeloupe",
  // GU: "Guam",
  // GT: "Guatemala",
  // GG: "Guernsey",
  // GN: "Guinea",
  // GW: "Guinea-Bissau",
  // GY: "Guyana",
  // HT: "Haiti",
  // HM: "Heard Island and Mcdonald Islands",
  // VA: "Holy See (Vatican City State)",
  // HN: "Honduras",
  // HK: "Hong Kong",
  // HU: "Hungary",
  // IS: "Iceland",
  // IN: "India",
  // ID: "Indonesia",
  // IR: "Iran, Islamic Republic Of",
  // IQ: "Iraq",
  // IE: "Ireland",
  // IM: "Isle of Man",
  // IL: "Israel",
  // IT: "Italy",
  // JM: "Jamaica",
  // JP: "Japan",
  // JE: "Jersey",
  // JO: "Jordan",
  // KZ: "Kazakhstan",
  // KE: "Kenya",
  // KI: "Kiribati",
  // KP: "Korea, Democratic People'S Republic of",
  // KR: "Korea, Republic of",
  // KW: "Kuwait",
  // KG: "Kyrgyzstan",
  // LA: "Lao People'S Democratic Republic",
  // LV: "Latvia",
  // LB: "Lebanon",
  // LS: "Lesotho",
  // LR: "Liberia",
  // LY: "Libyan Arab Jamahiriya",
  // LI: "Liechtenstein",
  // LT: "Lithuania",
  // LU: "Luxembourg",
  // MO: "Macao",
  // MK: "Macedonia, The Former Yugoslav Republic of",
  // MG: "Madagascar",
  // MW: "Malawi",
  // MY: "Malaysia",
  // MV: "Maldives",
  // ML: "Mali",
  // MT: "Malta",
  // MH: "Marshall Islands",
  // MQ: "Martinique",
  // MR: "Mauritania",
  // MU: "Mauritius",
  // YT: "Mayotte",
  // MX: "Mexico",
  // FM: "Micronesia, Federated States of",
  // MD: "Moldova, Republic of",
  // MC: "Monaco",
  // MN: "Mongolia",
  // MS: "Montserrat",
  // MA: "Morocco",
  // MZ: "Mozambique",
  // MM: "Myanmar",
  // NA: "Namibia",
  // NR: "Nauru",
  // NP: "Nepal",
  // NL: "Netherlands",
  // AN: "Netherlands Antilles",
  // NC: "New Caledonia",
  // NZ: "New Zealand",
  // NI: "Nicaragua",
  // NE: "Niger",
  // NG: "Nigeria",
  // NU: "Niue",
  // NF: "Norfolk Island",
  // MP: "Northern Mariana Islands",
  // NO: "Norway",
  // OM: "Oman",
  // PK: "Pakistan",
  // PW: "Palau",
  // PS: "Palestinian Territory, Occupied",
  // PA: "Panama",
  // PG: "Papua New Guinea",
  // PY: "Paraguay",
  // PE: "Peru",
  // PH: "Philippines",
  // PN: "Pitcairn",
  // PL: "Poland",
  // PT: "Portugal",
  // PR: "Puerto Rico",
  // QA: "Qatar",
  // RE: "Reunion",
  // RO: "Romania",
  // RU: "Russian Federation",
  // RW: "RWANDA",
  // SH: "Saint Helena",
  // KN: "Saint Kitts and Nevis",
  // LC: "Saint Lucia",
  // PM: "Saint Pierre and Miquelon",
  // VC: "Saint Vincent and the Grenadines",
  // WS: "Samoa",
  // SM: "San Marino",
  // ST: "Sao Tome and Principe",
  // SA: "Saudi Arabia",
  // SN: "Senegal",
  // CS: "Serbia and Montenegro",
  // SC: "Seychelles",
  // SL: "Sierra Leone",
  // SG: "Singapore",
  // SK: "Slovakia",
  // SI: "Slovenia",
  // SB: "Solomon Islands",
  // SO: "Somalia",
  // ZA: "South Africa",
  // GS: "South Georgia and the South Sandwich Islands",
  // ES: "Spain",
  // LK: "Sri Lanka",
  // SD: "Sudan",
  // SR: "Suriname",
  // SJ: "Svalbard and Jan Mayen",
  // SZ: "Swaziland",
  // SE: "Sweden",
  // CH: "Switzerland",
  // SY: "Syrian Arab Republic",
  // TW: "Taiwan, Province of China",
  // TJ: "Tajikistan",
  // TZ: "Tanzania, United Republic of",
  // TH: "Thailand",
  // TL: "Timor-Leste",
  // TG: "Togo",
  // TK: "Tokelau",
  // TO: "Tonga",
  // TT: "Trinidad and Tobago",
  // TN: "Tunisia",
  // TR: "Turkey",
  // TM: "Turkmenistan",
  // TC: "Turks and Caicos Islands",
  // TV: "Tuvalu",
  // UG: "Uganda",
  // UA: "Ukraine",
  // AE: "United Arab Emirates",
  // GB: "United Kingdom",
  US: "United States",
  // UM: "United States Minor Outlying Islands",
  // UY: "Uruguay",
  // UZ: "Uzbekistan",
  // VU: "Vanuatu",
  // VE: "Venezuela",
  // VN: "Viet Nam",
  // VG: "Virgin Islands, British",
  // VI: "Virgin Islands, U.S.",
  // WF: "Wallis and Futuna",
  // EH: "Western Sahara",
  // YE: "Yemen",
  // ZM: "Zambia",
  // ZW: "Zimbabwe",
};

export const regionMap: { [key: string]: { [key: string]: string } } = {
  US: {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CZ: "Canal Zone",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    VI: "Virgin Islands",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
};

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL || "",
  gatewayOrigin: process.env.REACT_APP_PLAYTIME_GATEWAY_ORIGIN || "/",
  gatewayWebSocketOrigin:
    process.env.REACT_APP_PLAYTIME_GATEWAY_WS_ORIGIN || "",
  gitTag: process.env.REACT_APP_GIT_TAG || process.env.STORYBOOK_GIT_TAG || "",
  joinBaseUrl: process.env.REACT_APP_JOIN_BASE_URL || "",
  joinHost: process.env.REACT_APP_JOIN_BASE_URL
    ? new URL(process.env.REACT_APP_JOIN_BASE_URL).host
    : "",
  shortBaseUrl: process.env.REACT_APP_SHORT_BASE_URL || "",
  translationsPathPrefix:
    process.env.REACT_APP_TRANSLATIONS_PATH_PREFIX ||
    process.env.STORYBOOK_TRANSLATIONS_PATH_PREFIX ||
    "",
  axiosRewriteAvatarsEnabled:
    !!process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_OLD &&
    !!process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_NEW &&
    process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_OLD !==
      process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_NEW,
  axiosRewriteAvatarsUrlOldRegExp: process.env
    .REACT_APP_AXIOS_REWRITE_AVATARS_URL_OLD
    ? new RegExp(process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_OLD, "g")
    : undefined,
  axiosRewriteAvatarsUrlNew:
    process.env.REACT_APP_AXIOS_REWRITE_AVATARS_URL_NEW,
  axiosRewriteFilesEnabled:
    !!process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_OLD &&
    !!process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_NEW &&
    process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_OLD !==
      process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_NEW,
  axiosRewriteFilesUrlOldRegExp: process.env
    .REACT_APP_AXIOS_REWRITE_FILES_URL_OLD
    ? new RegExp(process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_OLD, "g")
    : undefined,
  axiosRewriteFilesUrlNew: process.env.REACT_APP_AXIOS_REWRITE_FILES_URL_NEW,
  nodeEnv: process.env.NODE_ENV,
};

export const joinCodeNumbers: { [key: string]: Array<string> } = {
  0: ["Zero"],
  1: ["One"],
  2: ["Two"],
  3: ["Three"],
  4: ["Four"],
  5: ["Five"],
  6: ["Six"],
  7: ["Seven"],
  8: ["Eight"],
  9: ["Nine"],
};

export const joinCodeVerbs: { [key: string]: Array<string> } = {
  a: ["abbreviate", "absorb", "admire", "adopt", "alter"],
  b: ["balance", "become", "behold", "bend", "borrow"],
  c: ["call", "carry", "chase", "cheer", "coach"],
  d: ["decline", "defeat", "defend", "discuss", "desire"],
  e: ["elect", "elude", "excite", "enchant", "emulate"],
  f: ["flatten", "follow", "fetch", "find", "flee"],
  g: ["gain", "get", "glimpse", "goad", "gobble"],
  h: ["hide", "hypnotize", "haunt", "heal", "help"],
  i: ["idolize", "imagine", "imitate", "impress", "influence"],
  j: ["jab", "juggle", "jest", "join", "judge"],
  k: ["kid", "know", "kick", "kern", "kanoodle"],
  l: ["lead", "leap", "leave", "liberate", "lift"],
  m: ["mimic", "match", "measure", "meet", "mention"],
  n: ["noodle", "nab", "name", "need", "nurture"],
  o: ["obey", "oblige", "observe", "obtain", "offend"],
  p: ["pay", "persuade", "pick", "praise", "present"],
  q: ["quadruple", "quiz", "query", "quash", "quell"],
  r: ["race", "rebuff", "recall", "recommend", "reunite"],
  s: ["salute", "save", "scare", "scold", "seek"],
  t: ["tally", "tame", "tap", "tempt", "thrill"],
  u: ["unearth", "update", "unload", "unify", "unveil"],
  v: ["validate", "value", "vanquish", "visit", "vex"],
  w: ["wake", "want", "warn", "weigh", "welcome"],
  x: ["xerox", "x-ray"],
  y: ["yield", "yo-yo", "yaw"],
  z: ["zap", "zing", "zest", "zombify"],
};

export const joinCodeInanimatePluralNouns: { [key: string]: Array<string> } = {
  a: [
    "avocados",
    "atoms",
    "apples",
    "albums",
    "alarms",
    "adolescents",
    "avalanches",
    "axes",
    "abodes",
    "alarms",
    "amplifiers",
  ],
  b: [
    "beets",
    "biscuits",
    "beans",
    "blueberries",
    "basins",
    "boots",
    "bandanas",
    "beepers",
    "bands",
    "brooms",
  ],
  c: [
    "cherries",
    "cells",
    "cactii",
    "cookies",
    "coconuts",
    "calzones",
    "cheeses",
    "cabinets",
    "computers",
    "chips",
  ],
  d: [
    "dumbbells",
    "drills",
    "drumsticks",
    "donuts",
    "doorknobs",
    "dresses",
    "discs",
    "droids",
    "dictionaries",
    "dice",
  ],
  e: [
    "eyes",
    "excavators",
    "espressos",
    "erasers",
    "electrons",
    "easels",
    "earrings",
    "engines",
    "elixirs",
    "elevators",
  ],
  f: [
    "frocks",
    "films",
    "ferns",
    "factories",
    "faces",
    "fiddles",
    "flutes",
    "flotillas",
    "farms",
    "freezers",
  ],
  g: [
    "grills",
    "grapes",
    "guavas",
    "gyros",
    "gumdrops",
    "gowns",
    "gifts",
    "globes",
    "garments",
    "gloves",
  ],
  h: [
    "hurricanes",
    "horseshoes",
    "helicopters",
    "heartbeats",
    "hamburgers",
    "hammocks",
    "hoodies",
    "helmets",
    "headphones",
    "houses",
  ],
  i: [
    "islands",
    "ingots",
    "infernos",
    "indicators",
    "igloos",
    "impediments",
    "imprints",
    "illustrations",
    "indexes",
    "ingredients",
  ],
  j: [
    "jewels",
    "jets",
    "jeeps",
    "jars",
    "jalapeños",
    "jackets",
    "joysticks",
    "jalopies",
    "jetliners",
    "jeans",
  ],
  k: [
    "kiwis",
    "keyboards",
    "kettles",
    "kebabs",
    "kayaks",
    "kerchiefs",
    "kites",
    "kinescopes",
    "keys",
    "kazoos",
  ],
  l: [
    "lifeboats",
    "lemons",
    "lanterns",
    "leeks",
    "laptops",
    "ladles",
    "lightbulbs",
    "limousines",
    "lawnmowers",
    "lockers",
  ],
  m: [
    "mushrooms",
    "muffins",
    "molecules",
    "minds",
    "meteors",
    "maracas",
    "models",
    "mops",
    "mints",
    "maps",
  ],
  n: [
    "nametags",
    "nachos",
    "nectarines",
    "napkins",
    "notes",
    "neckties",
    "numbers",
    "nights",
    "noses",
    "noodles",
  ],
  o: [
    "orbs",
    "onions",
    "olives",
    "oceans",
    "oaks",
    "oars",
    "oddities",
    "ocarinas",
    "orchestras",
    "oboes",
  ],
  p: [
    "pumpkins",
    "planets",
    "pines",
    "pineapples",
    "peppers",
    "pears",
    "papayas",
    "parsnips",
    "peanuts",
    "pods",
  ],
  q: [
    "quotations",
    "quills",
    "quests",
    "queries",
    "quarks",
    "quarries",
    "quays",
    "quads",
    "quarrels",
    "quivers",
  ],
  r: [
    "roses",
    "rocks",
    "rays",
    "raisins",
    "raviolis",
    "radiators",
    "rings",
    "ribbons",
    "razors",
    "racks",
  ],
  s: [
    "storms",
    "skulls",
    "ships",
    "seashells",
    "sausages",
    "seeds",
    "spanakopitas",
    "soybeans",
    "shorts",
    "stoves",
  ],
  t: [
    "tires",
    "tomatoes",
    "taxis",
    "tankers",
    "tacos",
    "tortillas",
    "turnips",
    "tables",
    "tutus",
    "tuxedos",
  ],
  u: [
    "units",
    "umbrellas",
    "ukuleles",
    "underpants",
    "underpasses",
    "uniforms",
    "universes",
    "uprights",
    "ulcers",
    "uvulas",
  ],
  v: [
    "volcanos",
    "vests",
    "vehicles",
    "vans",
    "vegetables",
    "valets",
    "vanities",
    "visors",
    "volleyballs",
    "vaults",
  ],
  w: [
    "whistles",
    "watermelons",
    "walnuts",
    "wontons",
    "willows",
    "whoppers",
    "windows",
    "wrenches",
    "waffles",
    "workstations",
  ],
  x: ["x-rays", "xboxes", "xeroxes", "xylophone", "xenon"],
  y: ["yaks", "yards", "yawls", "yokes", "yuccas"],
  z: ["zebras", "zambonis", "zippers", "zoos", "zeppelins"],
};

export const joinCodeAdjectives: { [key: string]: Array<string> } = {
  a: [
    "ace",
    "active",
    "arctic",
    "alert",
    "amusing",
    "adorable",
    "adventurous",
    "animated",
    "attentive",
    "authentic",
    "abstract",
    "accessible",
    "adept",
    "adroit",
    "affable",
  ],
  b: [
    "busy",
    "big",
    "bright",
    "brave",
    "beautiful",
    "bubbly",
    "bouncy",
    "brazen",
    "blistering",
    "burly",
    "bustling",
    "benevolent",
    "benign",
    "buoyant",
    "brawny",
  ],
  c: [
    "caring",
    "clever",
    "cheerful",
    "cozy",
    "cheeky",
    "calm",
    "colorful",
    "courageous",
    "curious",
    "charming",
    "carefree",
    "candid",
    "cantankerous",
    "capricious",
    "cryptic",
  ],
  d: [
    "delicious",
    "dry",
    "dramatic",
    "deep",
    "dusty",
    "daring",
    "digital",
    "delighted",
    "demanding",
    "dapper",
    "definitive",
    "daunting",
    "decorous",
    "detached",
    "diligent",
  ],
  e: [
    "expensive",
    "eager",
    "excited",
    "endless",
    "enormous",
    "energetic",
    "earnest",
    "ecstatic",
    "effortless",
    "enchanting",
    "eternal",
    "eccentric",
    "eclectic",
    "empirical",
    "enigmatic",
  ],
  f: [
    "foggy",
    "fast",
    "funny",
    "fresh",
    "fair",
    "friendly",
    "fluttering",
    "fuzzy",
    "fantastic",
    "frosty",
    "flashy",
    "felicitous",
    "fervent",
    "frank",
    "flawless",
  ],
  g: [
    "good",
    "giant",
    "gentle",
    "generous",
    "great",
    "grand",
    "gritty",
    "graceful",
    "gorgeous",
    "gilded",
    "glowing",
    "glossy",
    "gregarious",
    "grandiose",
    "grizzled",
  ],
  h: [
    "happy",
    "healthy",
    "hopeful",
    "helpful",
    "hidden",
    "humble",
    "harmless",
    "hilarious",
    "handsome",
    "heartfelt",
    "harmonious",
    "hasty",
    "honorable",
    "hospitable",
    "humongous",
  ],
  i: [
    "impactful",
    "ideal",
    "icey",
    "identical",
    "imaginative",
    "important",
    "immense",
    "infamous",
    "idyllic",
    "intense",
    "illustrious",
    "impartial",
    "incisive",
    "indispensable",
  ],
  j: [
    "jolting",
    "jazzy",
    "jolly",
    "joyful",
    "juicy",
    "jumbo",
    "jumpy",
    "junior",
    "jovial",
    "jagged",
    "jocular",
    "juvenile",
    "jaunty",
    "judicious",
    "jubilant",
  ],
  k: [
    "kind",
    "keen",
    "kindly",
    "known",
    "knowing",
    "knobby",
    "knowledgeable",
    "kooky",
    "kind-hearted",
    "klutzy",
    "kaleidoscopic",
    "keeled",
    "keyless",
    "kinetic",
    "kingly",
  ],
  l: [
    "lucky",
    "lounging",
    "loud",
    "long",
    "large",
    "loyal",
    "lovely",
    "leafy",
    "liquid",
    "lively",
    "lavish",
    "luxurious",
    "lawful",
    "loquacious",
    "luminous",
  ],
  m: [
    "musical",
    "mild",
    "mad",
    "mighty",
    "massive",
    "major",
    "monumental",
    "marvelous",
    "microscopic",
    "mysterious",
    "modest",
    "multicolored",
    "malevolent",
    "meticulous",
    "melodic",
  ],
  n: [
    "natural",
    "national",
    "neat",
    "nice",
    "new",
    "nosy",
    "noisy",
    "nifty",
    "notable",
    "nimble",
    "nocturnal",
    "nebulous",
    "nonchalant",
    "nutritious",
    "novel",
  ],
  o: [
    "old",
    "odd",
    "ornate",
    "obvious",
    "offbeat",
    "official",
    "organic",
    "opulent",
    "overt",
    "oblong",
    "objective",
    "obscure",
    "obstinate",
    "onerous",
    "ostentatious",
  ],
  p: [
    "proud",
    "pretty",
    "playful",
    "polite",
    "prickly",
    "popular",
    "picturesque",
    "pleasant",
    "perpetual",
    "peculiar",
    "promising",
    "pensive",
    "pernicious",
    "pious",
    "pragmatic",
  ],
  q: [
    "quantum",
    "quaking",
    "quiet",
    "quick",
    "quaint",
    "quirky",
    "qualified",
    "quizzical",
    "queasy",
    "quixotic",
    "quarrelsome",
    "quintessential",
    "quadraphonic",
    "quadruple",
    "quality",
  ],
  r: [
    "round",
    "rough",
    "rare",
    "rapid",
    "royal",
    "rowdy",
    "ripe",
    "restless",
    "relaxed",
    "rambunctious",
    "renowned",
    "resilient",
    "redolent",
    "resolute",
    "rigorous",
  ],
  s: [
    "silly",
    "sweet",
    "sharp",
    "strong",
    "surprising",
    "silent",
    "sizzling",
    "sturdy",
    "shining",
    "soaring",
    "splendid",
    "sublime",
    "serene",
    "sonorous",
    "strident",
  ],
  t: [
    "tall",
    "tasty",
    "tough",
    "thrifty",
    "talented",
    "talkative",
    "tranquil",
    "towering",
    "trendy",
    "threadbare",
    "thunderous",
    "thoughtful",
    "temperate",
    "tenacious",
    "therapeutic",
  ],
  u: [
    "useful",
    "ultimate",
    "uniform",
    "upright",
    "uncommon",
    "united",
    "unusual",
    "upbeat",
    "unsung",
    "unequaled",
    "unassailable",
    "understated",
    "unprecedented",
    "unparallelled",
    "ultra",
  ],
  v: [
    "vast",
    "valuable",
    "vibrant",
    "vivid",
    "valid",
    "verdant",
    "victorious",
    "vigorous",
    "virtual",
    "veiled",
    "venerable",
    "verbose",
    "versatile",
    "vociferous",
    "vigilant",
  ],
  w: [
    "warm",
    "wild",
    "wise",
    "wonderful",
    "wavy",
    "windy",
    "whimsical",
    "wistful",
    "warmhearted",
    "whirlwind",
    "whipping",
    "wry",
    "welcoming",
    "wholehearted",
    "willful",
  ],
  x: ["xenogeneic", "xenial", "xerothermic", "xeric"],
  y: ["yawning", "youthful", "yellowed", "yelping", "yappy"],
  z: ["zany", "zealous", "zestful", "zippy", "zen"],
};

export const roundIntroDurationSeconds = 3;
export const roundIntroDurationMillis = roundIntroDurationSeconds * 1000;

export const quickPlayCarePackageRoundIntroDurationSeconds = 7;
export const quickPlayCarePackageRoundIntroDurationMillis =
  quickPlayCarePackageRoundIntroDurationSeconds * 1000;

export const roundQuestionOnlySeconds = roundIntroDurationSeconds + 5;

export const userReservedColor = "#ff00ff";
export const hairReservedColor = "#0000ff";
export const skinReservedColor = "#00ff19";

export const hairColorHexCodes = [
  "#EFEFE4",
  "#D3D194",
  "#E2CF59",
  "#B8855A",
  "#7A5F49",
  "#6D492B",
  "#563923",
  "#3D2911",
  "#1D0C02",
  "#0F231E",
  "#78787A",
  "#CA8359",
  "#C74E1C",
  "#5B1C14",
  "#CD0000",
  "#F9C8E8",
  "#E961B1",
  "#B8BE14",
  "#3F4F14",
  "#2EB1A5",
  "#0F5F53",
  "#66E0ED",
  "#0066BD",
  "#CCCDE5",
  "#686BA6",
];

export const skinToneHexCodes = [
  "#F4E6DC",
  "#F5DDC3",
  "#E7BD9C",
  "#ECBCA2",
  "#D1A27E",
  "#E4A374",
  "#EEC48F",
  "#DEB67A",
  "#C8945D",
  "#B17D5D",
  "#B97849",
  "#A9663E",
  "#905532",
  "#87573B",
  "#6F5139",
  "#733E19",
  "#5B2F11",
  "#4E3823",
  "#3F2714",
  "#33261C",
];

export const isUserTypingInterval = 6000;

export type AvatarAnimationTypes =
  | "BuzzerSmack"
  | "BuzzerWait"
  | "BuzzerInterstitialHorizontal"
  | "BuzzerInterstitialVertical"
  | "ClassmateClues"
  | "Defeat"
  | "Victory"
  | "Idle"
  | "LobbyStudentActivate"
  | "LobbyStudentIdle"
  | "LobbyStudentIdleShiny"
  | "Locker"
  | "LockerArms"
  | "LockerFeet"
  | "LockerHat"
  | "LockerHead"
  | "LockerTorso"
  | "Pose"
  | "StageWalk";
export const AvatarAnimations: Record<
  AvatarAnimationTypes,
  Array<IAvatarAnimation>
> = {
  BuzzerSmack: [{ name: "buzzer_smack", loop: false }],
  BuzzerWait: [{ name: "buzzer_wait", loop: true }],
  BuzzerInterstitialHorizontal: [
    { name: "buzzer_interstitial_horizontal", loop: true },
  ],
  BuzzerInterstitialVertical: [
    { name: "buzzer_interstitial_vertical", loop: true },
  ],
  Defeat: [
    { name: "defeat_01", loop: false },
    { name: "defeat_02", loop: false },
    { name: "defeat_03", loop: true },
    { name: "defeat_04", loop: true },
    { name: "defeat_05", loop: true },
    { name: "defeat_06", loop: false },
    { name: "defeat_07", loop: false },
    { name: "defeat_08", loop: false },
    { name: "defeat_09", loop: false },
    { name: "defeat_10", loop: false },
    { name: "defeat_11", loop: false },
    { name: "defeat_12", loop: false },
  ],
  Victory: [
    { name: "victory_01", loop: true },
    { name: "victory_02", loop: true },
    { name: "victory_03", loop: true },
    { name: "victory_04", loop: true },
    { name: "victory_05", loop: true },
    { name: "victory_06", loop: true },
    { name: "victory_07", loop: true },
    { name: "victory_08", loop: true },
    { name: "victory_09", loop: true },
    { name: "victory_10", loop: false },
    { name: "victory_11", loop: true },
    { name: "victory_12", loop: false },
    { name: "victory_13", loop: false },
    { name: "victory_14", loop: false },
  ],
  Idle: [
    { name: "idle_01", loop: true },
    { name: "idle_02", loop: true },
    { name: "idle_03", loop: true },
    { name: "idle_04", loop: true },
    { name: "idle_05", loop: true },
    { name: "idle_06", loop: true },
  ],
  LobbyStudentActivate: [
    { name: "platform_activate_01", loop: false },
    { name: "platform_activate_02", loop: false },
    { name: "platform_activate_03", loop: false },
  ],
  LobbyStudentIdle: [
    { name: "platform_idle_01", loop: true },
    { name: "platform_idle_02", loop: true },
    { name: "platform_idle_03", loop: true },
  ],
  LobbyStudentIdleShiny: [
    { name: "platform_idle_shiny_01", loop: true },
    { name: "platform_idle_shiny_02", loop: true },
    { name: "platform_idle_shiny_03", loop: true },
  ],
  Locker: [{ name: "locker_0_idle", loop: true }],
  LockerArms: [
    { name: "locker_arms_01", loop: false },
    { name: "locker_arms_02", loop: false },
    { name: "locker_arms_03", loop: false },
    { name: "locker_arms_04", loop: false },
  ],
  LockerFeet: [
    { name: "locker_feet_01", loop: false },
    { name: "locker_feet_02", loop: false },
    { name: "locker_feet_03", loop: false },
    { name: "locker_feet_04", loop: false },
  ],
  LockerHat: [
    { name: "locker_hat-hair_01", loop: false },
    { name: "locker_hat-hair_02", loop: false },
    { name: "locker_hat-hair_03", loop: false },
    { name: "locker_hat-hair_04", loop: false },
  ],
  LockerHead: [
    { name: "locker_head_01", loop: false },
    { name: "locker_head_02", loop: false },
    { name: "locker_head_03", loop: false },
  ],
  LockerTorso: [
    { name: "locker_torso_01", loop: false },
    { name: "locker_torso_02", loop: false },
    { name: "locker_torso_03", loop: false },
    { name: "locker_torso_04", loop: false },
    { name: "locker_torso_05", loop: false },
    { name: "locker_torso_06", loop: false },
    { name: "locker_torso_07", loop: false },
  ],
  Pose: [
    { name: "poses/buddy_1", loop: false },
    { name: "poses/buddy_2", loop: false },
    { name: "poses/chill_1", loop: false },
    { name: "poses/chill_2", loop: false },
    { name: "poses/chill_3", loop: false },
    { name: "poses/fun_1", loop: false },
    { name: "poses/fun_2", loop: false },
    { name: "poses/fun_3", loop: false },
    { name: "poses/hi_1", loop: false },
    { name: "poses/hi_2", loop: false },
    { name: "poses/hi_3", loop: false },
    { name: "poses/peace_1", loop: false },
    { name: "poses/peace_2", loop: false },
    { name: "poses/peace_3", loop: false },
    { name: "poses/strong_1", loop: false },
    { name: "poses/strong_2", loop: false },
    { name: "poses/strong_3", loop: false },
    { name: "poses/thumbs_up_1", loop: false },
    { name: "poses/thumbs_up_2", loop: false },
    { name: "poses/thumbs_up_3", loop: false },
    { name: "poses/victory_1", loop: false },
    { name: "poses/victory_2", loop: false },
    { name: "poses/victory_3", loop: false },
    { name: "poses/victory_4", loop: false },
    { name: "poses/victory_5", loop: false },
  ],
  ClassmateClues: [
    { name: "classmate_clues/cardpose_01", loop: false },
    { name: "classmate_clues/cardpose_02", loop: false },
    { name: "classmate_clues/cardpose_03", loop: false },
    { name: "classmate_clues/cardpose_04", loop: false },
    { name: "classmate_clues/cardpose_05", loop: false },
    { name: "classmate_clues/cardpose_06", loop: false },
    { name: "classmate_clues/cardpose_07", loop: false },
    { name: "classmate_clues/cardpose_08", loop: false },
    { name: "classmate_clues/cardpose_09", loop: false },
    { name: "classmate_clues/cardpose_10", loop: false },
    { name: "classmate_clues/cardpose_11", loop: false },
    { name: "classmate_clues/cardpose_12", loop: false },
    { name: "classmate_clues/cardpose_13", loop: false },
    { name: "classmate_clues/cardpose_14", loop: false },
    { name: "classmate_clues/cardpose_15", loop: false },
    { name: "classmate_clues/cardpose_16", loop: false },
    { name: "classmate_clues/cardpose_17", loop: false },
    { name: "classmate_clues/cardpose_18", loop: false },
    { name: "classmate_clues/cardpose_19", loop: false },
    { name: "classmate_clues/cardpose_20", loop: false },
  ],
  StageWalk: [
    { name: "stageview_walk_01", loop: false },
    { name: "stageview_walk_02", loop: false },
    { name: "stageview_walk_03", loop: false },
  ],
};

// [backgroundRGBs, foregroundRGBs]
export const CoverImageBGColorSchemes: Record<
  CoverImageBGColorSchemeType,
  [[number, number, number], [number, number, number]]
> = {
  LIGHT_BLUE: [
    [210, 249, 249],
    [184, 245, 245],
  ],
  BLUE: [
    [0, 163, 222],
    [5, 148, 211],
  ],
  LIGHT_PINK: [
    [251, 214, 238],
    [249, 200, 232],
  ],
  PINK: [
    [229, 85, 178],
    [219, 58, 150],
  ],
  RED: [
    [229, 48, 35],
    [221, 24, 20],
  ],
  ORANGE: [
    [241, 168, 63],
    [234, 139, 39],
  ],
  YELLOW: [
    [255, 189, 0],
    [255, 176, 24],
  ],
  GREEN: [
    [184, 190, 20],
    [170, 170, 17],
  ],
  TEAL: [
    [0, 188, 161],
    [7, 173, 149],
  ],
  PURPLE: [
    [88, 94, 147],
    [73, 76, 137],
  ],
  DARK_GREEN: [
    [39, 70, 48],
    [29, 59, 42],
  ],
  DARK_ORANGE: [
    [199, 78, 28],
    [183, 55, 26],
  ],
};

export const RemixBGColorSchemes: Record<
  RemixBGColorSchemeType,
  [[number, number, number], [number, number, number]]
> = {
  ELECTIVES: [
    [223, 67, 150],
    [226, 89, 177],
  ],
  ENGLISH: [
    [0, 133, 207],
    [0, 152, 221],
  ],
  HISTORY: [
    [73, 76, 136],
    [92, 98, 155],
  ],
  MATHEMATICS: [
    [158, 166, 11],
    [178, 178, 20],
  ],
  OTHER: [
    [255, 176, 24],
    [255, 198, 41],
  ],
  REDEMPTION: [
    [27, 150, 136],
    [42, 168, 153],
  ],
  SCIENCE: [
    [81, 100, 82],
    [93, 117, 93],
  ],
  VISUAL_AND_PERFORMING_ARTS: [
    [177, 51, 16],
    [188, 69, 27],
  ],
  WORLD_LANGUAGES: [
    [229, 48, 35],
    [239, 72, 55],
  ],
};

export const subjectIdToIconDataMap: {
  [key: string]:
    | {
        color: ColorScheme;
        coverImageIcon: CoverImageIconType | RemixIconType;
      }
    | undefined;
} = {
  "2": {
    color: "monaco.base-highlight-02",
    coverImageIcon: "MATHEMATICS_NUMBERS_AND_OPERATIONS",
  },
  "3": {
    color: "anjou.base-highlight-02",
    coverImageIcon: "SCIENCE_CHEMISTRY",
  },
  "4": {
    color: "tangerine.base-highlight-02",
    coverImageIcon: "HISTORY_WORLD_HISTORY",
  },
  "5": {
    color: "golden.base-highlight-02",
    coverImageIcon: "WORLD_LANGUAGES_REMIX",
  },
  "6": {
    color: "pepto.base-highlight-02",
    coverImageIcon: "OTHER_REMIX",
  },
  "7": {
    color: "tomato.base-highlight-02",
    coverImageIcon: "ENGLISH_LITERATURE",
  },
  "8": {
    color: "capri.base-highlight-02",
    coverImageIcon: "VISUAL_AND_PERFORMING_ARTS_DANCE",
  },
  "9": {
    color: "lilac.base-highlight-02",
    coverImageIcon: "ELECTIVES_REMIX",
  },
};

export const TeacherTitleOptions = [
  "Teacher",
  "Superintendent",
  "School or District Administrator",
  "Tech Admin / IT Director",
  "Student Safety / Health Services",
  "Curriculum and Instruction",
  "Consultant",
  "Counselor",
  "Librarian or Media Specialist",
  "Parent / Caregiver",
  "Student",
  "Other",
].map((title) => {
  return { label: title, value: title };
});

export const MAX_IMAGE_UPLOAD_SIZE = 10 * 1024 * 1024; // 10 MB

export const DEFAULT_HAIR_COLOR_HEX_CODE = "#4b2e1a";

export const DEFAULT_SKIN_TONE_COLOR_HEX_CODE = "#808080";

export const MAX_ALT_TEXT_LENGTH = 2048;

export const MAX_DIAGRAM_LABEL_LENGTH = 255;

export const PUBLIC_SEARCH_PER_PAGE = 9;
export const LIBRARY_HOMEPAGE_SEARCH_PER_PAGE = 24;

export const TEACHER_REPORTING_PRACTICE_SET_LOW_ACCURACY_THRESHOLD = 60;
export const TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD = 80;

export const momentFormatString = "MMMM Do YYYY; h:mm A";

export const PREMIUM_SUPPORT_URL =
  "https://support.giantsteps.app/s/article/Pear-Practice-Premium-Features";
export const TEACHER_ADVOCATE_FOR_PREMIUM_URL =
  "https://support.giantsteps.app/s/article/Updates-to-Premium-Subscription-Trials-for-Teachers";
export const ADVOKIT_URL =
  "https://view.highspot.com/viewer/663d0cca78f3c7318cd4e731";
export const SHARE_THE_PEAR_URL =
  "https://www.peardeck.com/resources-community/advocacy/share-the-pear-request";
export const COPPA_URL = "https://www.peardeck.com/policies/coppa-disclosure";
export const INSTANT_SET_SUPPORT_URL =
  "https://support.giantsteps.app/s/article/Generate-a-Instant-Set-with-AI";
export const APPCUES_PREMIUM_MODAL_FLOW_ID =
  "9828ddcd-9f30-4522-ac74-1be06ac8eccf";
export const APPCUES_LIVE_PRACTICE_ENCOURAGEMENT_FLOW_ID =
  "ce8d8bb7-dece-4986-99da-98967b07fced";
export const REVIEW_CLASSROOM_REPORT_SUPPORT_URL =
  "https://support.giantsteps.app/s/article/Review-Classroom-Reports";
