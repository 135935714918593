import { Box, HStack, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import {
  CleverAccountButton,
  ExternalAccountButton,
  GoogleAccountButton,
  MicrosoftAccountButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { generateTeacherName } from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { AnalyticsEvent, IUser, UserRole } from "links/lib/types";

import { ChangeToStudentComfirmModal } from "../ChangeToStudentConfirmModal";

export interface IProps {
  teacher: IUser;
  linkedAccount: "Clever" | "Google" | "Microsoft";
  externalAccountLink: string;
  onChangeToStudent?: () => void;
}

const AccountButtons: Record<string, ExternalAccountButton> = {
  Clever: CleverAccountButton,
  Google: GoogleAccountButton,
  Microsoft: MicrosoftAccountButton,
};

export const TeacherLinkedAccountCard: React.FC<IProps> = ({
  teacher,
  linkedAccount,
  externalAccountLink,
  onChangeToStudent,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { trackEvent } = useAnalytics();
  const styles = useMultiStyleConfig("AdminTeacherLinkedAccountCard", {});
  const AccountButton = AccountButtons[linkedAccount];
  const [isRoleChangeModalOpen, setIsRoleChangeModalOpen] =
    useState<boolean>(false);

  const _onChangeToStudent = (isConfirmed?: boolean) => {
    setIsRoleChangeModalOpen(false);
    if (isConfirmed) {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Account_ChangeToStudentConfirm,
        {}
      );
      onChangeToStudent?.();
    }
  };

  const onOpenChangeToStudentModal = () => {
    setIsRoleChangeModalOpen(true);
    trackEvent(
      AnalyticsEvent.TeacherDashboard_Account_ChangeToStudentIntent,
      {}
    );
  };

  return (
    <Card
      sx={styles.card}
      variant="adminCardThickBorder"
      borderColor="primary.tan"
    >
      <HStack flexWrap="wrap" w="full" justifyContent="space-between">
        <Box>
          <Heading sx={styles.heading} as="h2" variant="adminH6">
            {generateTeacherName(teacher)?.primary}
          </Heading>
          <Text variant="adminP1" color="primary.dark-gray">
            {teacher.email}
          </Text>

          {!!externalAccountLink && (
            <AccountButton sx={styles.button} href={externalAccountLink} />
          )}
        </Box>
        {teacher.role === UserRole.Teacher && !!onChangeToStudent && (
          <Button
            variant="adminTextButtonLarge"
            onClick={onOpenChangeToStudentModal}
          >
            {t("myAccountScreen.changeToStudentButtonLabel")}
          </Button>
        )}
      </HStack>
      {!!onChangeToStudent && (
        <ChangeToStudentComfirmModal
          isOpen={isRoleChangeModalOpen}
          handleClose={_onChangeToStudent}
        />
      )}
    </Card>
  );
};
