import { useEffect } from "react";

// Store provided contexts in a map. We cannot use a standard React context
// provider here because the PDL Instant Content Drawer component is not a child
// of any of the components that provide the "page context."
const contexts: Map<string, string> = new Map();

// useProvidePDLInstantContentPageContext is a hook that provides context from
// the current page ("page context") to the PDL Instant Content Drawer The
// context can be read using the usePDLInstantContentPageContext hook.
export const useProvidePDLInstantContentPageContext = (
  key: string,
  value?: string
): void => {
  useEffect(() => {
    if (value) {
      contexts.set(key, value);
    }
    return () => {
      contexts.delete(key);
    };
  }, [key, value]);
};

// getPDLInstantContentPageContext returns the "page context" provided by
// useProvidePDLInstantContentPageContext. If multiple contexts are provided,
// they are joined by `\n\n`. Order is determined by key.
export const getPDLInstantContentPageContext = (): string | undefined => {
  if (contexts.size === 0) {
    return undefined;
  }
  return [...contexts.keys()]
    .sort()
    .map((key) => contexts.get(key))
    .join("\n\n");
};
