import { ContextType } from "react";

import { AxiosContext } from "../../contexts/axios";
import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";

export interface IUpdateUserRoleResponse {
  token: string;
}

export interface IUpdateUserRoleParams {
  user_id: string;
  role: string;
}

export const updateUserRole = async (
  axios: ContextType<typeof AxiosContext>,
  user_id: string,
  role: string
): Promise<IUpdateUserRoleResponse> => {
  return await axios
    .put(`/v1/users/${user_id}/role`, {
      role,
    })
    .then((r) => r.data as IUpdateUserRoleResponse);
};

export default function useUpdateUserRole(): IUseAsyncReturn<
  IUpdateUserRoleResponse,
  IUpdateUserRoleParams
> {
  const axios = useAxios();

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IUpdateUserRoleResponse,
    IUpdateUserRoleParams
  >(({ user_id, role }) => updateUserRole(axios, user_id, role));

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
