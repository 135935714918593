import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IExtendUserIndividualTrialProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export default function useExtendHATUserIndividualTrial(
  props?: IExtendUserIndividualTrialProps
): UseMutationResult<unknown, unknown, void> {
  const axios = useAxios();

  const ExtendUserIndividualTrial = async () => {
    await axios({
      method: "post",
      url: "/v1/subscriptions/individual/extension/hat-extend",
    });
  };

  const mutation = useMutation(ExtendUserIndividualTrial, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
  });

  return mutation;
}
