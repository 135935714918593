import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { MoveStudentModal } from "adminComponents/organisms/MoveStudentModal";
import {
  UserCardProps,
  UsersTab,
} from "adminComponents/screens/ClassroomDetailScreen/components/UsersTab";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  useCreateCustomSessionGroups,
  useFetchAverageUserAccuracies,
  useFetchClassroomUsers,
  useFetchClassrooms,
  useFetchCustomSessionGroups,
  useFetchPendingClassroomStudents,
  useMoveStudent,
} from "links/lib/features/classrooms";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import {
  AnalyticsPage,
  IClassroom,
  IGradeLevel,
  IUser,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import { useEditStudent } from "screens/TeacherClassrooms/shared/hooks/useEditStudent";

import { useAddStudentsByEmail } from "./hooks/useAddStudentsByEmail";
import { useAddStudentsByGClassroom } from "./hooks/useAddStudentsByGClassroom";
import { useAddStudentsByLink } from "./hooks/useAddStudentsByLink";

export interface IStudentsTabProps {
  classroom: IClassroom;
}

export const StudentsTab: React.FC<IStudentsTabProps> = ({ classroom }) => {
  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_Students);

  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { authUser, isFeatureEnabled } = useAuth();

  const classrooms = useFetchClassrooms({});

  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroom.id,
    group_roles: [UsersGroupsRole.Member],
  });
  useErrorToast(classroomStudentsFetch.error);

  const moveStudent = useMoveStudent({
    onSuccess: () => showToast(t("classDetailTabs.moveStudentSuccessToast")),
  });
  useErrorToast(moveStudent.error);

  const pendingStudentsFetch = useFetchPendingClassroomStudents({
    classroom_id: classroom.id,
    // look for pending students every 10 seconds
    refetchInterval: 10000,
  });
  useErrorToast(pendingStudentsFetch.error);

  const averageUserAccuraciesFetch = useFetchAverageUserAccuracies({
    classroom_id: classroom.id,
  });
  useErrorToast(averageUserAccuraciesFetch.error);

  const gradeLevelsFetch = useFetchGradeLevels({
    country: authUser?.country || "US",
    limit: 100,
  });
  useErrorToast(gradeLevelsFetch.error);

  const customSessionGroupsFetch = useFetchCustomSessionGroups({
    classroom_id: classroom.id,
  });
  useErrorToast(customSessionGroupsFetch.error);

  const createCustomSessionGroupsMutation = useCreateCustomSessionGroups({
    onSuccess: () => showToast(t("classDetailTabs.sessionGroupsSuccessToast")),
  });
  useErrorToast(createCustomSessionGroupsMutation.error);

  const {
    handleAddIntent: handleAddByEmailIntent,
    modal: addByEmailModal,
    addStudentDirectionsModal,
  } = useAddStudentsByEmail({ classroom_id: classroom.id });
  const {
    handleGenerateLinkIntent,
    generateLinkModal,
    handleApproveStudent,
    handleApproveAllStudents,
    handleRejectStudentIntent,
    handleRejectAllStudentsIntent,
    rejectStudentModal,
    addStudentDirectionsModal: addStudentDirectionsModalByLink,
  } = useAddStudentsByLink({
    classroom_id: classroom.id,
  });
  const {
    handleAddIntent: handleAddIntentByGClassroom,
    authorizeModal,
    classroomSelectModal,
    studentSelectModal,
    confirmLinkGoogleClassroomModal,
    addStudentDirectionsModal: addStudentDirectionsModalbyGoogle,
  } = useAddStudentsByGClassroom({
    classroom,
    classroomStudents: classroomStudentsFetch.data?.users,
  });
  const { editModal, handleEditIntent } = useEditStudent({
    classroom_id: classroom.id,
    gradeLevels: gradeLevelsFetch.data?.gradeLevels || [],
  });

  const {
    isOpen: isOpenMoveStudent,
    onOpen: onOpenMoveStudent,
    onClose: onCloseMoveStudent,
  } = useDisclosure();
  const [moveStudentUser, setMoveStudentUser] = useState<IUser>();
  const handleMoveStudentToDifferentClassroom = (user: IUser) => {
    setMoveStudentUser(user);
    onOpenMoveStudent();
  };

  const handleMoveStudentSubmit = (user: IUser, newClassroomId: string) => {
    moveStudent.mutate({
      original_classroom_id: classroom.id,
      user_id: user.id,
      new_classroom_id: newClassroomId,
    });
  };

  const studentsData = useMemo<Array<UserCardProps>>(() => {
    const accuracyMap: { [key: string]: number } = {};
    const gradeLevelMap: { [key: string]: IGradeLevel } = {};
    const data: Array<UserCardProps> = [];

    gradeLevelsFetch.data?.gradeLevels.forEach((gradeLevel) => {
      gradeLevelMap[gradeLevel.id] = gradeLevel;
    });

    averageUserAccuraciesFetch.data?.average_user_accuracies.forEach((a) => {
      accuracyMap[a.user_id] = Math.round(a.average_accuracy * 100) || 0;
    });

    classroomStudentsFetch.data?.users.forEach((user) => {
      data.push({
        user: user,
        accuracy: accuracyMap[user.id],
        gradeLevel: user.student_grade_level_id
          ? gradeLevelMap[user.student_grade_level_id]
          : undefined,
        usersGroupsRole: classroomStudentsFetch.data?.users_groups.find(
          (ug) => ug.user_id === user.id
        )?.user_role,
      });
    });

    pendingStudentsFetch.data?.pending_users.forEach(({ user, id }) => {
      data.push({
        user: user,
        pendingUserId: id,
      });
    });

    data.sort((a, b) => {
      const aName = generateStudentName(a.user);
      const bName = generateStudentName(b.user);

      return sortAlpha(aName.primary, bName.primary);
    });

    return data;
  }, [
    averageUserAccuraciesFetch.data?.average_user_accuracies,
    classroomStudentsFetch.data?.users,
    classroomStudentsFetch.data?.users_groups,
    pendingStudentsFetch?.data?.pending_users,
    gradeLevelsFetch.data?.gradeLevels,
  ]);

  const isLoading =
    classroomStudentsFetch.isLoading ||
    averageUserAccuraciesFetch.isLoading ||
    pendingStudentsFetch.isLoading ||
    gradeLevelsFetch.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <UsersTab
          classroom={classroom}
          usersData={studentsData}
          handleAddByEmail={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleAddByEmailIntent
              : undefined
          }
          handleAddStudentsFromGoogleClassroom={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleAddIntentByGClassroom
              : undefined
          }
          handleApproveStudent={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleApproveStudent
              : undefined
          }
          handleApproveAllStudents={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleApproveAllStudents
              : undefined
          }
          handleEdit={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleEditIntent
              : undefined
          }
          handleGenerateClassroomLink={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleGenerateLinkIntent
              : undefined
          }
          handleMoveStudentToDifferentClassroom={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleMoveStudentToDifferentClassroom
              : undefined
          }
          handleRejectStudent={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleRejectStudentIntent
              : undefined
          }
          handleRejectAllStudents={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleRejectAllStudentsIntent
              : undefined
          }
          usersRole={UserRole.Student}
          usersGroupsRole={classroom.users_groups_role}
          customSessionGroups={customSessionGroupsFetch.data?.session_groups}
          handleCreateCustomGroups={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? (customSessionGroups) => {
                  createCustomSessionGroupsMutation.mutate({
                    classroom_id: classroom.id,
                    session_groups: customSessionGroups,
                  });
                }
              : undefined
          }
          flagTeachers={isFeatureEnabled(
            "playtime.enable_teacher_to_student_role_change"
          )}
        />
      )}
      {addByEmailModal}
      {addStudentDirectionsModal}
      {addStudentDirectionsModalbyGoogle}
      {addStudentDirectionsModalByLink}
      {generateLinkModal}
      {authorizeModal}
      {classroomSelectModal}
      {studentSelectModal}
      {rejectStudentModal}
      {confirmLinkGoogleClassroomModal}
      {editModal}

      <MoveStudentModal
        isOpen={isOpenMoveStudent}
        onClose={onCloseMoveStudent}
        handleSubmit={handleMoveStudentSubmit}
        classrooms={(classrooms.data?.classrooms || []).filter(
          (a) => a.id !== classroom.id
        )}
        student={moveStudentUser}
      />
    </>
  );
};
