import { ISubject } from "links/lib/types";

interface IParams {
  selectedParentSubjectIds: string[];
  subjects: ISubject[];
}

// Fan out parent subjects selection into parent+children
export const getParentAndChildSubjectIds = ({
  selectedParentSubjectIds,
  subjects,
}: IParams): string[] => {
  const selectedSubjectIds: string[] = [];

  selectedParentSubjectIds.forEach((parentSubjectId) => {
    selectedSubjectIds.push(parentSubjectId);

    const childSubjectIds = subjects
      .filter((s) => s.parent_id === parentSubjectId)
      .map((s) => s.id);

    selectedSubjectIds.push(...childSubjectIds);
  });

  return selectedSubjectIds;
};
