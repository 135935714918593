import { Box } from "@chakra-ui/react";
import "@goguardian/types-psi";
import { ShowGenerateContentDrawerOptions } from "@goguardian/types-psi";
import React, { useEffect, useMemo, useRef } from "react";

import { getPDLInstantContentPageContext } from "adminComponents/hooks/useProvidePDLInstantContentPageContext";
import { AdminFlyout } from "adminComponents/molecules/Flyout";

export interface IPDLInstantContentFlyoutProps {
  isOpen: boolean;
  handleBack?: () => void;
  handleClose: () => void;
}

export const PDLInstantContentFlyout: React.FC<
  IPDLInstantContentFlyoutProps
> = ({ isOpen, handleBack, handleClose }) => {
  // TODO: pre-select grade level (requires PP -> PDL grade level mapping)

  const pageContext = useMemo(
    () => getPDLInstantContentPageContext(),
    // Only update the context when the flyout state changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  return (
    <AdminFlyout isOpen={isOpen} showClose={false} onClose={handleClose}>
      <PDLInstantContent
        options={{
          onClickClose: handleClose,
          onClickBack: handleBack,
          context: pageContext,
        }}
      />
    </AdminFlyout>
  );
};

const PDLInstantContent: React.FC<{
  options: Omit<ShowGenerateContentDrawerOptions, "target">;
}> = ({ options }) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = targetRef.current;
    if (!target) return;
    window.pear.generate.showGenerateContentDrawer({
      ...options,
      target,
    });
    return () => {
      target.replaceChildren();
    };
    // Do not re-run this effect when the options change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box ref={targetRef} h="full" />;
};
