import React from "react";

import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { SeasonLocation } from "sharedComponents/molecules/SeasonLocation";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";

import BlusteryBaySVG from "./resource/blustery_bay.svg";
import BrilliantBeachSVG from "./resource/brilliant_beach.svg";
import DazzlingDunesSVG from "./resource/dazzling_dunes.svg";
import MammothMountainsSVG from "./resource/mammoth_mountains.svg";
import PearopolisSVG from "./resource/pearopolis.svg";
import PearyPrairieSVG from "./resource/peary_prairie.svg";

export interface ISeason5LocationProps {
  width: number;
  height: number;
  canUnlock: boolean;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  locationId: string;
  pathColor: string;
  isLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
}

const locationToBGMap: { [locationId: string]: string } = {
  37: BlusteryBaySVG,
  38: PearyPrairieSVG,
  39: DazzlingDunesSVG,
  40: BrilliantBeachSVG,
  41: MammothMountainsSVG,
  42: PearopolisSVG,
};

export const Season5Location: React.FC<ISeason5LocationProps> = ({
  width,
  height,
  avatarItems,
  avatarItemGroups,
  locationId,
  pathColor,
  isLoading,
  avatarAtlasUrl,
  avatarSkeletonUrl,
  handleRequestUnlock,
  canUnlock,
  avatar,
}) => {
  return (
    <SeasonLocation
      width={width}
      height={height}
      canUnlock={canUnlock}
      avatarAtlasUrl={avatarAtlasUrl}
      avatarSkeletonUrl={avatarSkeletonUrl}
      handleRequestUnlock={handleRequestUnlock}
      avatarItems={avatarItems}
      avatarItemGroups={avatarItemGroups}
      pathColor={pathColor}
      isLoading={isLoading}
      avatar={avatar}
      bgSVG={locationToBGMap[locationId]}
      avatarScaleConstant={3000}
      avatarOffsetYRatioConstant={0.26}
      roadColor="#102421"
    />
  );
};
