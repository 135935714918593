import { useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMount, usePrevious } from "react-use";

import { XPBoostModal } from "adminComponents/molecules/XPBoostModal";
import { StudentHomepage } from "adminComponents/screens/StudentHomepage";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { useFetchChallengesStatus } from "links/lib/features/challenges";
import {
  useFetchCurrentSeason,
  useFetchSeasonMap,
} from "links/lib/features/season";
import { useFetchPracticeSessionsStreak } from "links/lib/features/sessions";
import { useFetchSocialEvents } from "links/lib/features/social";
import useCreateSocialEventReaction from "links/lib/features/social/useCreateSocialEventReaction";
import useFetchSocialEventReactions from "links/lib/features/social/useFetchSocialEventReactions";
import { useGetClassroomLiveBanners } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IAssignment,
  IPeriodicChallenges,
  IPracticeSet,
  IPracticeSetSession,
  IRemix,
  SessionType,
  SocialEvent,
} from "links/lib/types";
import { getNRandomArrayElements, isSameDate } from "links/lib/util";
import { usePlaySession } from "screens/StudentDashboard/contexts/PlaySessionProvider";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";
import { useStartAssignment } from "sharedComponents/hooks/useStartAssignment";

import { useRemixes } from "./hooks/useRemixes";
import Season1MapPNG from "./resource/season_1_mapfull.png";
import Season2MapPNG from "./resource/season_2_mapfull.png";
import Season3MapPNG from "./resource/season_3_mapfull.png";
import Season4MapPNG from "./resource/season_4_mapfull.png";
import Season5MapPNG from "./resource/season_5_mapfull.png";
import { showSocialEventReactionsToasts } from "./utils/showSocialEventReactionToasts";

const MAX_PRACTICE_SETS = 3;

export const StudentHome: React.FC = () => {
  const { authUser } = useAuth();
  const {
    navigationData,
    handleOpenAssignmentFlyout: _handleOpenAssignmentFlyout,
  } = useNavigationData();
  const [currentPracticeSets, setCurrentPracticeSets] = useState<
    Array<{
      practice_set: IPracticeSet;
      maximum_xp: number;
      item_count: number;
      classroom_id: string;
    }>
  >([]);
  const history = useHistory();
  const { handlePlaySession, handleSetRedirectToSeasonMap, pendingSession } =
    usePlaySession();
  useMount(() => {
    handleSetRedirectToSeasonMap(false);
  });
  const {
    activeAssignments,
    practiceSetDetailMap,
    liveSessionMap,
    isLoading: isStudentDashboardDataLoading,
  } = useStudentDashboardData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "studentHomepageContainer",
  });
  const { trackEvent } = useAnalytics();

  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.StudentDashboard_Home);

  const daysUntilEndOfMonth = useMemo(
    () => moment().endOf("month").diff(moment(), "days"),
    []
  );

  const { remixes } = useRemixes({ liveSessionMap });

  const fetchStreak = useFetchPracticeSessionsStreak();
  useErrorToast(fetchStreak.error);

  const fetchChallenges = useFetchChallengesStatus();
  useErrorToast(fetchChallenges.error);

  const fetchSocialEvents = useFetchSocialEvents();
  useErrorToast(fetchSocialEvents.error);

  const createSocialEventReaction = useCreateSocialEventReaction();
  useErrorToast(createSocialEventReaction.error);

  const {
    isOpen: isOpenXpBoostModal,
    onClose: onCloseXpBoostModal,
    onOpen: onOpenXpBoostModal,
  } = useDisclosure();

  const userXPMultiplier = createSocialEventReaction.data?.user_xp_multiplier;

  const prevUserXPMultiplier = usePrevious(userXPMultiplier);
  useEffect(() => {
    if (!prevUserXPMultiplier && userXPMultiplier) {
      onOpenXpBoostModal();
      trackEvent(
        AnalyticsEvent.StudentDashboard_Home_SocialEventXPBoostAwarded,
        {}
      );
    }
  }, [onOpenXpBoostModal, prevUserXPMultiplier, userXPMultiplier, trackEvent]);

  const showToast = useShowToast();
  const { t: tReactions } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "highFivesCard.social.reactions",
  });
  const fetchSocialEventReactions = useFetchSocialEventReactions();
  useErrorToast(fetchSocialEventReactions.error);
  useEffect(() => {
    showSocialEventReactionsToasts(
      fetchSocialEventReactions.data?.social_event_reactions || [],
      showToast,
      tReactions
    );
  }, [fetchSocialEventReactions.data, showToast, tReactions]);

  const fetchSeason = useFetchCurrentSeason();
  useErrorToast(fetchSeason.error);

  const fetchSeasonMap = useFetchSeasonMap();
  useErrorToast(fetchSeasonMap.error);

  const daysUntilEndOfSeason = useMemo(() => {
    const season = fetchSeason.data?.season;

    if (!season) return 0;

    return moment(season.end_date).diff(moment(), "d");
  }, [fetchSeason.data]);

  const practiceSetDetails = useMemo(
    () => Object.values(practiceSetDetailMap),
    [practiceSetDetailMap]
  );

  const handleShufflePracticeSets = useCallback(
    (prioritizeXp?: boolean) => {
      if (!prioritizeXp) {
        // Only track shuffle if it is user intent
        trackEvent(
          AnalyticsEvent.StudentDashboard_Home_ShufflePracticeSets,
          {}
        );
      }

      if (practiceSetDetails.length <= MAX_PRACTICE_SETS) {
        setCurrentPracticeSets(practiceSetDetails);
        return;
      }

      if (!prioritizeXp) {
        const [sets] = getNRandomArrayElements(
          practiceSetDetails,
          MAX_PRACTICE_SETS
        );
        setCurrentPracticeSets(sets);
      } else {
        practiceSetDetails.sort((a, b) => {
          if (a.maximum_xp > b.maximum_xp) {
            return -1;
          } else if (b.maximum_xp > a.maximum_xp) {
            return 1;
          } else {
            return 0;
          }
        });

        setCurrentPracticeSets(practiceSetDetails.slice(0, MAX_PRACTICE_SETS));
      }
    },
    [trackEvent, practiceSetDetails]
  );

  // If no practice sets have been selected and they are present
  // then select practice sets (prioritizing XP for initial load)
  useEffect(() => {
    if (!currentPracticeSets.length && practiceSetDetails.length) {
      handleShufflePracticeSets(true);
    }
  }, [
    currentPracticeSets.length,
    practiceSetDetails.length,
    handleShufflePracticeSets,
  ]);

  const handleStartAssignment = (assignment: IAssignment) => {
    trackEvent(AnalyticsEvent.StudentDashboard_Home_PlayAssignment, {
      assignmentId: assignment.id,
    });
  };

  const handleGrantAccess = () => {
    trackEvent(AnalyticsEvent.StudentDashboard_Home_Grant_Access, {});
  };

  const { authorizeModal, startAssignment, pendingAssignment } =
    useStartAssignment({
      assignments: activeAssignments,
      handleGrantAccess,
      handleStartAssignment,
    });

  const handleAssignmentsPlayButton = (assignment: IAssignment) => {
    startAssignment(assignment);
  };

  const handlePracticeSetPlayButton = (
    practiceSet: IPracticeSet,
    classroomId: string
  ) => {
    const existingSessionsForSet = Object.values(liveSessionMap).filter(
      (session) =>
        session.practice_set_id === practiceSet.id &&
        session.session_type === SessionType.Individual
    );

    if (existingSessionsForSet.length > 1) {
      console.warn(
        "Found multiple incomplete sessions for set; joining most recent session as a priority"
      );
      existingSessionsForSet.sort((a, b) =>
        new Date(a.start_time) < new Date(b.start_time) ? 1 : -1
      );
    }

    handlePlaySession({
      practiceSetId: practiceSet.id,
      classroomId,
      existingSessionJoinCode: existingSessionsForSet[0]?.join_code,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Home_PlayPracticeSet, {
      practiceSetId: practiceSet.id,
    });
  };

  const handleRemixClick = (remix: IRemix) => {
    if (remix.session_id === "0") {
      if (remix.practice_set_id !== "0") {
        handlePlaySession({
          practiceSetId: remix.practice_set_id,
          classroomId: "0",
        });
      } else {
        handlePlaySession({
          subjectId: remix.subject_id,
          onDemandIntent: remix.on_demand_intent,
          practiceSetId: "0",
          classroomId: "0",
        });
      }

      trackEvent(AnalyticsEvent.StudentDashboard_Home_PlayRemix, {
        subjectId: remix.subject_id,
        onDemandIntent: remix.on_demand_intent,
      });
    } else {
      const session = liveSessionMap[remix.session_id];

      if (!session) return;

      history.push(`/session/join/${session.join_code}`, {
        isIndividualSession: true,
      });

      trackEvent(AnalyticsEvent.StudentDashboard_Home_JoinRemix, {
        sessionId: remix.session_id,
        subjectId: remix.subject_id,
        onDemandIntent: remix.on_demand_intent,
      });
    }
  };

  const handleEditAvatar = () => {
    history.push("/s/locker");

    trackEvent(AnalyticsEvent.StudentDashboard_Home_EditAvatarClick, {});
  };

  const handleOpenMap = () => {
    history.push("/s/season-map");

    trackEvent(AnalyticsEvent.StudentDashboard_Home_OpenSeasonMapClick, {});
  };

  const handleOpenWelcomeCardModal = () => {
    return;
  };

  const handleDismissWelcomeCard = () => {
    return;
  };

  const handleViewClassesButton = () => {
    history.push("/s/my-classes");

    trackEvent(AnalyticsEvent.StudentDashboard_Home_MyClassesClick, {});
  };

  const handleJoinSession = (session: IPracticeSetSession) => {
    history.push(`/session/join/${session.join_code}`);

    trackEvent(AnalyticsEvent.StudentDashboard_Home_LiveBanner_JoinSession, {
      sessionId: session.id,
    });
  };

  const handleOpenAssignmentsFlyout = () => {
    _handleOpenAssignmentFlyout();

    trackEvent(AnalyticsEvent.StudentDashboard_Home_ViewMoreAssignments, {});
  };

  const handleSocialEventClick = (socialEvent: SocialEvent) => {
    if (socialEvent.is_reacted) return;

    trackEvent(AnalyticsEvent.StudentDashboard_Home_SocialEventReaction, {
      eventType: socialEvent.event_type,
    });
    createSocialEventReaction.mutate({ social_event_id: socialEvent.id });
  };

  const periodicChallenges: IPeriodicChallenges | undefined = (() => {
    if (!fetchChallenges.data) return;

    const { periodic_challenges } = fetchChallenges.data;

    const challenges: IPeriodicChallenges = {
      daily_challenge: {
        ...periodic_challenges.daily_challenge,
        title: t("challengeDailyTitle", {
          xpThreshold: periodic_challenges.daily_challenge.xp_threshold,
        }),
      },
      weekly_challenge: {
        ...periodic_challenges.weekly_challenge,
        title: t("challengeWeeklyTitle", {
          xpThreshold: periodic_challenges.weekly_challenge.xp_threshold,
        }),
      },
      monthly_challenge: periodic_challenges.monthly_challenge,
    };

    return challenges;
  })();

  const isLoading =
    fetchStreak.isLoading ||
    fetchChallenges.isLoading ||
    isStudentDashboardDataLoading;

  const now = new Date();

  const myAssignments = activeAssignments.map((assignment) => {
    const { best_user_attempts, practice_set_id } = assignment;

    return {
      assignment,
      accuracy: best_user_attempts.length ? best_user_attempts[0].accuracy : 0,
      rewardXp: practiceSetDetailMap[practice_set_id]?.maximum_xp ?? 0,
      isDueToday: isSameDate(now, new Date(assignment.ends_at)),
    };
  });

  const myPracticeSets = currentPracticeSets.map((psd) => ({
    practiceSet: psd.practice_set,
    rewardXp: psd.maximum_xp,
    classroomId: psd.classroom_id,
  }));

  const liveBanners = useGetClassroomLiveBanners({
    showClassroomName: true,
  });

  const currentLevelXP =
    (authUser?.student_season_total_xp ?? 0) -
    (authUser?.student_season_current_level_xp_threshold ?? 0);

  const nextLevelXP =
    (authUser?.student_season_next_level_xp_threshold ?? 0) -
    (authUser?.student_season_current_level_xp_threshold ?? 0);

  const pendingSessionAction = pendingSession || pendingAssignment;
  return (
    <>
      <StudentHomepage
        isLoading={isLoading}
        user={authUser}
        navigationData={navigationData}
        practiceSessionStreak={fetchStreak.data?.streak}
        periodicChallenges={periodicChallenges}
        monthlyChallengeRemainingDays={daysUntilEndOfMonth}
        seasonName={fetchSeason.data?.season.name}
        seasonChallengeDaysLeft={daysUntilEndOfSeason}
        seasonChallengeImageSrc={
          fetchSeason.data?.season.id === "6"
            ? Season5MapPNG
            : fetchSeason.data?.season.id === "5"
            ? Season4MapPNG
            : fetchSeason.data?.season.id === "4"
            ? Season3MapPNG
            : fetchSeason.data?.season.id === "3"
            ? Season2MapPNG
            : Season1MapPNG
        }
        seasonChallengeImageAlt={t("seasonChallengeAltText")}
        currentLevel={authUser?.student_season_level ?? 0}
        nextLevelXP={nextLevelXP}
        currentXP={currentLevelXP}
        ticketCount={fetchSeasonMap.data?.current_unexchanged_tickets}
        // TODO: Implement welcome card when onboarding ready
        showWelcomeCard={false}
        myAssignments={myAssignments}
        practiceSets={myPracticeSets}
        dailyRemixes={remixes}
        socialEvents={fetchSocialEvents.data?.social_events ?? []}
        liveBanners={liveBanners}
        pendingSession={pendingSessionAction}
        handleShufflePracticeSets={() => handleShufflePracticeSets(false)}
        handleEditAvatar={handleEditAvatar}
        handleOpenMap={handleOpenMap}
        handleAssignmentsPlayButton={handleAssignmentsPlayButton}
        handlePracticeSetPlayButton={handlePracticeSetPlayButton}
        handleOpenWelcomeCardModal={handleOpenWelcomeCardModal}
        handleDismissWelcomeCard={handleDismissWelcomeCard}
        handleViewClassesButton={handleViewClassesButton}
        handleOpenAssignmentsFlyout={handleOpenAssignmentsFlyout}
        handleRemixClick={handleRemixClick}
        handleJoinSession={handleJoinSession}
        handleSocialEventClick={handleSocialEventClick}
      />
      {authorizeModal}

      {!!userXPMultiplier && (
        <XPBoostModal
          isOpen={isOpenXpBoostModal}
          handleClose={onCloseXpBoostModal}
          userXPMultiplier={userXPMultiplier}
        />
      )}
    </>
  );
};
