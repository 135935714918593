import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AdminFlyout } from "adminComponents/molecules/Flyout";
import {
  IPracticeSetTypeSelectionProps,
  PracticeSetTypeSelection,
} from "adminComponents/molecules/PracticeSetTypeSelection";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSetType } from "links/lib/types";

import { IInstantSetFlyoutProps, InstantSetFlyout } from "../InstantSetFlyout";
import { PDLInstantContentFlyout } from "../PDLInstantContentFlyout";
import {
  IProps as IPracticeSetDetailProps,
  PracticeSetDetailFlyout,
} from "../PracticeSetDetailFlyout";
import { ISmartSetFlyoutProps, SmartSetFlyout } from "../SmartSetFlyout";

enum FlyoutState {
  TypeSelection = 1,
  SmartSet = 2,
  InstantSet = 3,
  CustomSet = 4,
  PDLInstantContent = 5,
}

export interface IProps {
  isOpen: boolean;
  typeSelection: Omit<
    IPracticeSetTypeSelectionProps,
    | "handleSelectCustom"
    | "handleSelectInstant"
    | "handleSelectSmartSet"
    | "handleSelectPDLInstantContent"
  >;
  customSet: Omit<
    IPracticeSetDetailProps,
    "isOpen" | "handleClose" | "showBack" | "handleBack"
  >;
  instantSet: Omit<
    IInstantSetFlyoutProps,
    "isOpen" | "handleClose" | "showBack" | "handleBack"
  >;
  smartSet: Omit<
    ISmartSetFlyoutProps,
    "isOpen" | "handleClose" | "showBack" | "handleBack"
  >;
  practiceSetType?: IPracticeSetType;
  handleClose: () => void;
}

export const CreatePracticeSetFlyout: React.FC<IProps> = ({
  isOpen,
  customSet,
  typeSelection,
  instantSet,
  smartSet,
  practiceSetType,
  handleClose,
}) => {
  let initialState = FlyoutState.TypeSelection;
  switch (practiceSetType) {
    case IPracticeSetType.Instant:
      initialState = FlyoutState.InstantSet;
      break;
    case IPracticeSetType.Custom:
      initialState = FlyoutState.CustomSet;
      break;
    case IPracticeSetType.Smart:
      initialState = FlyoutState.SmartSet;
      break;
    case IPracticeSetType.PLDInstantContent:
      initialState = FlyoutState.PDLInstantContent;
      break;
  }
  const [flyoutState, setFlyoutState] = React.useState(initialState);

  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "createPracticeSetFlyout",
  });

  const handleBack = () => {
    if (practiceSetType) return;
    setFlyoutState(FlyoutState.TypeSelection);
  };

  // Reset to type selection whenever the flyout is closed, as long
  // as a specific practice set type is not selected
  useEffect(() => {
    if (!isOpen && !practiceSetType) {
      setFlyoutState(FlyoutState.TypeSelection);
    }
  }, [isOpen, practiceSetType]);

  return (
    <>
      <AdminFlyout
        title={t("title")}
        isOpen={isOpen && flyoutState === FlyoutState.TypeSelection}
        onClose={handleClose}
      >
        <Box pb={pxToRem(20)}>
          <PracticeSetTypeSelection
            {...typeSelection}
            handleSelectSmartSet={() => setFlyoutState(FlyoutState.SmartSet)}
            handleSelectInstant={() => setFlyoutState(FlyoutState.InstantSet)}
            handleSelectCustom={() => setFlyoutState(FlyoutState.CustomSet)}
            handleSelectPDLInstantContent={() =>
              setFlyoutState(FlyoutState.PDLInstantContent)
            }
          />
        </Box>
      </AdminFlyout>

      <PracticeSetDetailFlyout
        {...customSet}
        showBack={!practiceSetType}
        isOpen={isOpen && flyoutState === FlyoutState.CustomSet}
        handleClose={handleClose}
        handleBack={handleBack}
      />

      <InstantSetFlyout
        {...instantSet}
        showBack={!practiceSetType}
        isOpen={isOpen && flyoutState === FlyoutState.InstantSet}
        handleClose={handleClose}
        handleBack={handleBack}
      />

      <SmartSetFlyout
        {...smartSet}
        showBack={!practiceSetType}
        isOpen={isOpen && flyoutState === FlyoutState.SmartSet}
        handleClose={handleClose}
        handleBack={handleBack}
      />

      <PDLInstantContentFlyout
        isOpen={isOpen && flyoutState === FlyoutState.PDLInstantContent}
        handleClose={handleClose}
        handleBack={handleBack}
      />
    </>
  );
};
