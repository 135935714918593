import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignment, ThirdParty } from "../../types";

export interface IMutateAssignmentArgs {
  id?: string;
  classroom_id: string;
  description: string;
  practice_set_id: string;
  required_duration_sec: number;
  required_score_percent: number;
  starts_at: string;
  ends_at: string;
  user_ids: Array<string>;
  start_now: boolean;
  third_party?: ThirdParty;
  third_party_assignment_id?: string;
  third_party_group_id?: string;
  randomize_item_order: boolean;
}

export interface IMutateAssignmentResponse {
  assignment: IAssignment;
  requires_third_party_authorization: boolean;
}

export interface IUseMutateAssignmentArgs {
  onSuccess?: (response: IMutateAssignmentResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

/**
 * Create or update an assignment
 * @returns
 */
export default function useMutateAssignment(
  args: IUseMutateAssignmentArgs
): UseMutationResult<
  IMutateAssignmentResponse,
  unknown,
  IMutateAssignmentArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateAssignment = async (args: IMutateAssignmentArgs) => {
    const {
      id,
      classroom_id,
      practice_set_id,
      description,
      required_duration_sec,
      required_score_percent,
      starts_at,
      ends_at,
      user_ids,
      start_now,
      third_party,
      third_party_assignment_id,
      third_party_group_id,
      randomize_item_order,
    } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/assignments${id ? "/" + id : ""}`,
      data: {
        assignment: {
          group_id: classroom_id,
          practice_set_id,
          description,
          required_duration_sec,
          required_score_percent,
          starts_at,
          ends_at,
          user_ids,
          third_party,
          third_party_assignment_id,
          third_party_group_id,
          randomize_item_order,
        },
        start_now,
      },
    }).then((r) => r.data as IMutateAssignmentResponse);

    if (!response.requires_third_party_authorization) {
      queryClient.invalidateQueries(["assignments", { classroom_id }]);
      queryClient.setQueryData(["assignments", { id: id }], {
        assignment: response.assignment,
      });
      queryClient.invalidateQueries("onboarding-checklist");
      queryClient.invalidateQueries(["recommendedPracticeSets"]);
    }

    return response;
  };

  const mutation = useMutation(mutateAssignment, {
    onError: args.onError,
    onSuccess: args.onSuccess,
    onSettled: args.onSettled,
  });

  return mutation;
}
