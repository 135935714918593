import {
  Modal as ChakraModal,
  HStack,
  Link,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { Modal as ModalTheme } from "adminComponents/theme/components/Modal";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const variants = Object.keys(ModalTheme.variants);

export interface IChangeToStudentConfirmModalProps
  extends Omit<ModalProps, "children" | "onClose"> {
  // True if the user confirmed; false if they cancelled; undefined/null if they closed without confirming or cancelling
  handleClose: (isConfirmed?: boolean) => void;
}

export const ChangeToStudentComfirmModal: React.FC<
  IChangeToStudentConfirmModalProps
> = ({ handleClose, size = "2xl", variant = "adminModal", ...rest }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [readConfirmed, setReadConfirmed] = useState<boolean>(false);

  const breakPointSize = useBreakpointValue({
    base: "full",
    md: size,
  });

  return (
    <ChakraModal
      closeOnOverlayClick
      onClose={() => handleClose()}
      size={breakPointSize}
      variant={variant}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {t("changeToStudentModal.heading")}
          </Heading>
        </ModalHeader>

        <CloseButtonWrapper top={pxToRem(24)} right={pxToRem(24)} left="unset">
          <IconCTAButton
            ariaLabel={t("common.close")}
            onClick={() => handleClose()}
          />
        </CloseButtonWrapper>

        <ModalBody>
          <VStack gap={pxToRem(10)} alignItems="flex-start">
            <Text color="primary.dark-gray" mt={pxToRem(8)} variant="adminP1">
              {t("changeToStudentModal.description")}
            </Text>
            <Text color="primary.dark-gray" mt={pxToRem(8)} variant="adminP1">
              <Trans
                i18nKey="changeToStudentModal.studentViewDescription"
                t={t}
              >
                <Link
                  target="_blank"
                  color="utility.link"
                  href="https://support.giantsteps.app/s/article/Open-Student-Mode"
                >
                  Learn more.
                </Link>
              </Trans>
            </Text>

            <Checkbox
              isChecked={readConfirmed}
              onChange={(e) => setReadConfirmed(e.target.checked)}
            >
              <Text>{t("changeToStudentModal.confirmCheckLabel")}</Text>
            </Checkbox>
            <HStack flexWrap="wrap" gap={pxToRem(8)}>
              <Button
                variant="adminTextButtonLarge"
                size="lg"
                w={["100%", null, "auto"]}
                onClick={() => handleClose(true)}
                disabled={!readConfirmed}
              >
                {t("changeToStudentModal.confirmButtonLabel")}
              </Button>
              <Button
                variant="adminButtonOutlined"
                size="lg"
                w={["100%", null, "auto"]}
                onClick={() => handleClose(false)}
              >
                {t("common.cancel")}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
