import React from "react";

import { SeasonMapMusic } from "links/lib/types";
import { AudioProvider } from "sharedComponents/contexts/audio";

import DozingInTheBackSeatMP3 from "./resource/dozingInTheBackSeat.mp3";
import DozingInTheBackSeatWebM from "./resource/dozingInTheBackSeat.webm";
import spriteData from "./resource/output.json";
import spriteMP3 from "./resource/output.mp3";
import spriteWebM from "./resource/output.webm";

const backgroundClips = {
  [SeasonMapMusic.DozingInTheBackSeat]: [
    { src: DozingInTheBackSeatWebM, type: "audio/webm" },
    { src: DozingInTheBackSeatMP3, type: "audio/mpeg" },
  ],
};

const SEASON_MAP_VOLUME_KEY = "SEASON_MAP_VOLUME";

export const Season5MapAudio: React.FC = ({ children }) => {
  return (
    <AudioProvider
      backgroundClips={backgroundClips}
      srcVolume={1}
      spriteSrc={[spriteWebM, spriteMP3]}
      sprite={spriteData.sprite}
      volumeStorageKey={SEASON_MAP_VOLUME_KEY}
    >
      {children}
    </AudioProvider>
  );
};
