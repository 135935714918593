import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Hide,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  Spacer,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Logo } from "adminComponents/atoms/Logo";
import { useCanUserExtendTrial } from "adminComponents/hooks/useCanUserExtendTrial";
import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import {
  chakraStylesGradeDropdown,
  chakraStylesSubjectDropdown,
} from "adminComponents/theme/components/Dropdown";
import { pxToRem } from "adminComponents/utils";
import { getParentAndChildSubjectIds } from "adminComponents/utils/getParentAndChildSubjectIDs";
import { useAnalytics } from "lib/contexts/analytics";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import {
  localStoreExtendPremiumDismissedKeyName,
  localStoreGracePeriodDeferralKeyName,
} from "links/lib/constants";
import { AnalyticsEvent, ISearchSuggestion, UserRole } from "links/lib/types";
import { IGuestNavigationDataContext } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";
import { usePremiumBannerInfo } from "sharedComponents/hooks/usePremiumBannerInfo";
import { sortGradeLevelsTwoColumns } from "sharedComponents/utils/sortGradeLevelsTwoColumns";

import { DismissableBanner } from "../DismissableBanner";
import { ExtendHATPremiumModal } from "../ExtendHATPremiumModal";
import { Link } from "../NavigationHeader/components/Link";
import { TeacherAccountMenu } from "../TeacherAccountMenu";

export const dropdownWidth = 130;

interface IProps {
  navigationData: IGuestNavigationDataContext | INavigationHeaderProps;
  handleSelectSubjects?: (subjectIds: string[]) => void;
  handleSelectGrades?: (gradeLevelIds: string[]) => void;
  handleCreatePracticeSet?: () => void;
  handleSearch?: (search: string) => void;
  handleClearSearchInput?: () => void;
  handleSuggest?: (searchTerm: string) => void;
  handleSignUp?: () => void;
  handleUpdateQuery?: (args: IHandleSetQueryArgs) => void;
  query?: ISearchQuery;
  isSearching?: boolean;
  suggestions?: string[];
  searchFilterSuggestions?: ISearchSuggestion[];
  isGuest?: boolean;
  hasNoPremiumAccess?: boolean;
  disableDropdowns?: boolean;
}

const searchCharLimit = 512;

export const TeacherNavigationHeader: React.FC<IProps> = ({
  navigationData,
  handleSelectSubjects,
  handleSelectGrades,
  handleCreatePracticeSet,
  handleSearch,
  handleClearSearchInput,
  handleSuggest,
  handleUpdateQuery,
  handleSignUp,
  query,
  isSearching,
  suggestions,
  searchFilterSuggestions,
  isGuest,
  hasNoPremiumAccess,
  disableDropdowns,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const history = useHistory();
  const xlOrLarger = useBreakpointValue({ base: false, xl: true });
  const buttonSize = useBreakpointValue({ base: "xs", sm: "xs", md: "md" });
  const [showMenu, toggleShowMenu] = useState(false);
  const menuRef = useRef();
  const {
    isOpen: isSmallScreenNavMenuOpen,
    onOpen: onOpenSmallScreenNavMenu,
    onClose: onCloseSmallScreenNavMenu,
  } = useDisclosure();
  const { trackEvent } = useAnalytics();

  const [isExtendPremiumModalOpen, setIsExtendPremiumModalOpen] =
    useState<boolean>(false);

  const onOpenExtendPremiumModal = () => {
    setIsExtendPremiumModalOpen(true);
    trackEvent(
      AnalyticsEvent.TeacherDashboard_Nav_Banner_HAT_Premium_Upgrade_Intent,
      {}
    );
  };

  const onExtendPremiumConfirm = () => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_Nav_Banner_HAT_Premium_Upgrade_Confirm,
      {}
    );
  };

  const handleAvatarClick = () => {
    toggleShowMenu((prev) => !prev);
  };

  const renderSearch =
    handleSearch &&
    handleClearSearchInput &&
    handleSuggest &&
    !window.location.pathname.endsWith("/t/explore");

  const subjectOptions = useMemo(
    () =>
      navigationData.parentSubjects.map((s) => ({
        label: s.name,
        value: s.id,
      })),
    [navigationData.parentSubjects]
  );

  const gradeLevelOptions = useMemo(() => {
    return sortGradeLevelsTwoColumns(navigationData.gradeLevels).map((g) => ({
      label: g.grade_level,
      value: g.id,
    }));
  }, [navigationData.gradeLevels]);

  const loggedInTeacherNavigationData = !isGuest
    ? (navigationData as INavigationHeaderProps)
    : undefined;

  const isInOrganization =
    loggedInTeacherNavigationData?.user?.organization_id &&
    loggedInTeacherNavigationData?.user.organization_id !== "0";

  const gracePeriodBannerInfo = usePremiumBannerInfo({
    gracePeriodEndDate: moment(
      loggedInTeacherNavigationData?.gracePeriodEndDate
    ),
    userRole: loggedInTeacherNavigationData?.user?.role,
    isInOrganization: !!isInOrganization,
    isTrial: !!loggedInTeacherNavigationData?.userLicense?.is_trial,
    licenseEndDate: moment(
      loggedInTeacherNavigationData?.userLicense?.end_date
    ),
  });

  const _handleSelectParentSubjects = (selectedParentSubjectIds: string[]) => {
    const selectedParentAndChildSubjectIds = getParentAndChildSubjectIds({
      selectedParentSubjectIds,
      subjects: navigationData.subjects,
    });

    handleSelectSubjects?.(selectedParentAndChildSubjectIds);
  };

  const showExtendPremiumBanner = useCanUserExtendTrial();

  return (
    <Box
      w="full"
      px={["admin.mobileXPadding", null, "admin.desktopXPadding"]}
      paddingTop={pxToRem(30)}
      paddingBottom={{
        base: pxToRem(90),
        sm: pxToRem(90),
        md: pxToRem(82),
        lg: pxToRem(82),
      }}
      height={{
        base: "50%",
        xl: "25%",
      }}
    >
      {/* Minimal navigation header, used on < lg breakpoints */}
      <Hide above="lg">
        <Flex
          w="full"
          gap={pxToRem(15)}
          justifyContent="space-between"
          height={{
            base: "100%",
            md: "50%",
            xl: "25%",
          }}
        >
          <Button
            ref={menuRef.current}
            onClick={onOpenSmallScreenNavMenu}
            variant="adminInlineIconButton"
            aria-label={t("common.openMenu")}
          >
            <Icon icon="menu" />
          </Button>
          <Drawer
            size="full"
            isOpen={isSmallScreenNavMenuOpen}
            placement="left"
            onClose={onCloseSmallScreenNavMenu}
            finalFocusRef={menuRef.current}
            variant="adminNavigation"
          >
            <DrawerOverlay />
            <DrawerContent h="100svh">
              <DrawerCloseButton
                as={IconCTAButton}
                ariaLabel={t("common.close")}
              />
              <DrawerBody>
                <Flex direction="column" h="full">
                  {loggedInTeacherNavigationData?.navigationLinks?.map(
                    (nl, index) => (
                      <Box key={index}>
                        <Link
                          as={RouterLink}
                          to={nl.to}
                          target={nl.isExternal ? "_blank" : undefined}
                        >
                          <Flex alignItems="center">
                            <Heading as="p" variant="adminH5">
                              {nl.label}
                            </Heading>
                            {nl.isExternal && (
                              <ExternalLinkIcon mx={pxToRem(8)} />
                            )}
                          </Flex>
                        </Link>
                        <Divider color="primary.tan" />
                      </Box>
                    )
                  )}
                  {navigationData.authActionLinks.map((aal, index) => (
                    <Box key={index}>
                      <Link
                        as={RouterLink}
                        to={aal.to}
                        target={aal.isExternal ? "_blank" : undefined}
                      >
                        <Flex alignItems="center">
                          <Heading as="p" variant="adminH5">
                            {aal.label}
                          </Heading>
                          {aal.isExternal && (
                            <ExternalLinkIcon mx={pxToRem(8)} />
                          )}
                        </Flex>
                      </Link>
                      <Divider color="primary.tan" />
                    </Box>
                  ))}

                  {!!handleCreatePracticeSet && (
                    <>
                      <Spacer />
                      <Button
                        onClick={() => {
                          onCloseSmallScreenNavMenu();
                          handleCreatePracticeSet();
                        }}
                        size="sm"
                        variant="adminButtonFilled"
                        mb={pxToRem(16)}
                        leftIcon={isGuest ? <Icon icon="sparkle" /> : undefined}
                      >
                        {t(
                          isGuest
                            ? "teacherHomepageLibrary.generateSet"
                            : "adminNavigationHeader.navigationLinks.buttonCreatePracticeSet"
                        )}
                      </Button>
                    </>
                  )}
                  {isGuest && !!handleSignUp && (
                    <>
                      <Button
                        onClick={() => {
                          onCloseSmallScreenNavMenu();
                          handleSignUp();
                        }}
                        size="sm"
                        variant="adminButtonFilled"
                        mb={pxToRem(16)}
                      >
                        {t("common.signUp")}
                      </Button>
                    </>
                  )}
                  {loggedInTeacherNavigationData?.user?.role ===
                    UserRole.Admin &&
                    loggedInTeacherNavigationData?.adminMode && (
                      <>
                        <Spacer />
                        <Button
                          size="sm"
                          as={RouterLink}
                          to="/t/explore"
                          variant="adminButtonFilled"
                          textAlign="center"
                        >
                          {t(
                            "adminNavigationHeader.navigationLinks.buttonCloseAdminMode"
                          )}
                        </Button>
                      </>
                    )}
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <HStack>
            {!isGuest && <Box display="inline" id="psi_launcher" />}
            <Logo
              width={pxToRem(150)}
              navigationLink={navigationData.logoLink}
            />
          </HStack>
          {!isGuest && loggedInTeacherNavigationData?.user && (
            <>
              <Popover
                variant="adminProfileMenu"
                isOpen={false}
                onOpen={handleAvatarClick}
                onClose={handleAvatarClick}
                placement="bottom-end"
              >
                <PopoverTrigger>
                  <Box position="relative">
                    <Avatar
                      {...loggedInTeacherNavigationData?.avatarData}
                      handleClick={() => {
                        // no-op, popover triggers opening of TeacherAccountMenu
                      }}
                      size="sm"
                      variant="adminAvatar"
                      showPremiumMarker={!hasNoPremiumAccess}
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent zIndex={12}>
                  <TeacherAccountMenu
                    user={loggedInTeacherNavigationData?.user}
                    accountLinks={loggedInTeacherNavigationData?.accountLinks}
                    handleClose={handleAvatarClick}
                    resourceLinks={loggedInTeacherNavigationData?.resourceLinks}
                    authActionLinks={navigationData.authActionLinks}
                    showPremiumMarker={!hasNoPremiumAccess}
                  />
                </PopoverContent>
              </Popover>
              <Box
                __css={{
                  marginTop: pxToRem(42),
                  display: showMenu ? "block" : "none",
                  opacity: { md: showMenu ? 1 : 0 },
                  transform: { md: showMenu ? "scale(1)" : "scale(0.5)" },
                  transformOrigin: { md: "top right" },
                  overflow: { md: "hidden" },
                  position: "absolute",
                  top: 0,
                  right: 0,
                  visibility: showMenu ? "visible" : "hidden",
                  transition: {
                    md: showMenu
                      ? "transform 250ms, opacity 250ms 150ms"
                      : "transform 250ms 150ms, opacity 250ms ",
                  },
                }}
              >
                <Drawer
                  size="full"
                  isOpen={showMenu}
                  placement="left"
                  onClose={handleAvatarClick}
                  variant="adminNavigation"
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton
                      as={IconCTAButton}
                      ariaLabel={t("common.close")}
                    />
                    <DrawerBody>
                      <TeacherAccountMenu
                        user={loggedInTeacherNavigationData?.user}
                        accountLinks={
                          loggedInTeacherNavigationData?.accountLinks
                        }
                        handleClose={handleAvatarClick}
                        resourceLinks={
                          loggedInTeacherNavigationData?.resourceLinks
                        }
                        authActionLinks={navigationData.authActionLinks}
                        showPremiumMarker={
                          loggedInTeacherNavigationData?.showPremiumMarker
                        }
                      />
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </Box>
            </>
          )}
        </Flex>

        {renderSearch && (
          <>
            <Box w="full" paddingTop={pxToRem(20)}>
              <SearchInput
                query={query}
                height={pxToRem(48)}
                handleClear={handleClearSearchInput}
                handleSearch={handleSearch}
                handleSuggest={handleSuggest}
                initialSearch={query?.term}
                placeholder={t("teacherHomepageLibrary.searchInputPlaceholder")}
                searching={isSearching}
                suggestions={suggestions}
                searchFilterSuggestions={searchFilterSuggestions}
                hideSearchButton
                characterLimit={searchCharLimit}
                handleUpdateQuery={handleUpdateQuery}
              />
            </Box>
          </>
        )}
      </Hide>

      {/* Fully expanded navigation header, used on >= lg breakpoints */}
      <Show above="lg">
        <Flex
          alignItems="center"
          gap={pxToRem(15)}
          height={{
            base: "100%",
            md: "50%",
            xl: "25%",
          }}
          w="full"
        >
          <HStack>
            {!isGuest && <Box display="inline" id="psi_launcher" />}
            <Logo
              width={pxToRem(150)}
              navigationLink={navigationData.logoLink}
            />
          </HStack>

          <HStack>
            {!isGuest && (
              <>
                {loggedInTeacherNavigationData?.navigationLinks?.map(
                  (nl, i) => (
                    <Link
                      key={`${i}-${nl.label}`}
                      as={RouterLink}
                      to={nl.to}
                      target={nl.isExternal ? "_blank" : undefined}
                      variant="adminNavLink"
                      aria-current={
                        navigationData.activeNavigationPath === nl.to
                      }
                    >
                      <Flex alignItems="center" whiteSpace="nowrap">
                        {nl.label}
                        {nl.isExternal && <ExternalLinkIcon mx={pxToRem(8)} />}
                      </Flex>
                    </Link>
                  )
                )}
              </>
            )}
          </HStack>
          {xlOrLarger && isGuest && handleSelectSubjects && handleSelectGrades && (
            <>
              <Box minW={pxToRem(dropdownWidth)} w={pxToRem(dropdownWidth)}>
                <Dropdown
                  isDisabled={isSearching || disableDropdowns}
                  isMulti
                  chakraStyles={chakraStylesSubjectDropdown({
                    dropdownWidth,
                  })}
                  isSearchable={false}
                  placeholder={t(
                    "teacherHomepageLibrary.dropdownPlaceholderBySubject"
                  )}
                  options={subjectOptions}
                  handleChange={(subjects) => {
                    _handleSelectParentSubjects(
                      (subjects as IOption[]).map((subject) => subject.value)
                    );
                  }}
                  value={subjectOptions.filter((subject) =>
                    query?.filters.subjectIds?.includes(subject.value)
                  )}
                  controlShouldRenderValue={false}
                />
              </Box>
              <Box minW={pxToRem(dropdownWidth)} w={pxToRem(dropdownWidth)}>
                <Dropdown
                  isDisabled={isSearching || disableDropdowns}
                  isMulti
                  chakraStyles={chakraStylesGradeDropdown({
                    dropdownWidth,
                  })}
                  isSearchable={false}
                  placeholder={t(
                    "teacherHomepageLibrary.dropdownPlaceholderByGrade"
                  )}
                  options={gradeLevelOptions}
                  handleChange={(grades) => {
                    handleSelectGrades(
                      (grades as IOption[]).map((grade) => grade.value)
                    );
                  }}
                  value={gradeLevelOptions.filter((gradeLevel: IOption) =>
                    query?.filters.gradeLevelIds?.includes(gradeLevel.value)
                  )}
                  controlShouldRenderValue={false}
                />
              </Box>
            </>
          )}

          {renderSearch && (
            <>
              <Spacer />
              <Box w="full" maxW={xlOrLarger ? "40%" : undefined}>
                <SearchInput
                  query={query}
                  height={pxToRem(48)}
                  handleClear={handleClearSearchInput}
                  handleSearch={handleSearch}
                  handleSuggest={handleSuggest}
                  initialSearch={query?.term}
                  placeholder={t(
                    "teacherHomepageLibrary.searchInputPlaceholder"
                  )}
                  searching={isSearching}
                  suggestions={suggestions}
                  searchFilterSuggestions={searchFilterSuggestions}
                  hideSearchButton
                  characterLimit={searchCharLimit}
                  handleUpdateQuery={handleUpdateQuery}
                />
              </Box>
            </>
          )}

          {!renderSearch && <Spacer />}

          <Button
            leftIcon={
              xlOrLarger && isGuest ? <Icon icon="sparkle" /> : undefined
            }
            onClick={() => {
              if (handleCreatePracticeSet) {
                handleCreatePracticeSet();
              } else if (isGuest) {
                history.push("/t/instant-sets");
              } else {
                console.error(
                  "No handler for create practice set provided to TeacherNavigationHeader"
                );
              }
            }}
            variant={isGuest ? "adminButtonWhite" : "adminButtonFilled"}
            size={buttonSize}
            minW={isGuest ? pxToRem(170) : pxToRem(100)}
          >
            {isGuest
              ? t("teacherHomepageLibrary.generateSet")
              : t("common.create")}
          </Button>

          {!isGuest && loggedInTeacherNavigationData?.user && (
            <Popover
              variant="adminProfileMenu"
              isOpen={showMenu}
              onOpen={handleAvatarClick}
              onClose={handleAvatarClick}
              placement="bottom-end"
            >
              <PopoverTrigger>
                <Box position="relative">
                  <Avatar
                    {...loggedInTeacherNavigationData?.avatarData}
                    handleClick={() => {
                      // no-op, popover triggers opening of TeacherAccountMenu
                    }}
                    size="sm"
                    variant="adminAvatar"
                    showPremiumMarker={!hasNoPremiumAccess}
                  />
                </Box>
              </PopoverTrigger>
              <PopoverContent zIndex={12}>
                <TeacherAccountMenu
                  position="relative"
                  user={loggedInTeacherNavigationData?.user}
                  accountLinks={loggedInTeacherNavigationData?.accountLinks}
                  handleClose={handleAvatarClick}
                  resourceLinks={loggedInTeacherNavigationData?.resourceLinks}
                  authActionLinks={navigationData.authActionLinks}
                  showPremiumMarker={!hasNoPremiumAccess}
                />
              </PopoverContent>
            </Popover>
          )}
          {isGuest && (
            <>
              <Button
                onClick={() => history.push("/sign-in/teacher")}
                variant="adminButtonWhite"
                size={buttonSize}
                minW={pxToRem(110)}
              >
                {t("common.signIn")}
              </Button>
              {!!handleSignUp && (
                <Button
                  onClick={handleSignUp}
                  variant="adminButtonFilled"
                  size={buttonSize}
                  minW={pxToRem(110)}
                >
                  {t("common.signUp")}
                </Button>
              )}
            </>
          )}
        </Flex>
      </Show>
      {loggedInTeacherNavigationData?.showGracePeriodBanner && (
        <Box marginTop={pxToRem(20)} marginBottom={pxToRem(-45)}>
          <DismissableBanner
            title={gracePeriodBannerInfo.title}
            subtitle={gracePeriodBannerInfo.subtitle}
            linkText={gracePeriodBannerInfo.linkText}
            linkTo={gracePeriodBannerInfo.linkTo}
            localStorageDismissedStateKey={localStoreGracePeriodDeferralKeyName}
          />
        </Box>
      )}
      {showExtendPremiumBanner && (
        <Box marginTop={pxToRem(20)} marginBottom={pxToRem(-45)}>
          <DismissableBanner
            title={t("extendHATPremium.bannerTitle")}
            localStorageDismissedStateKey={
              localStoreExtendPremiumDismissedKeyName
            }
            stayDismissedForever
            linkText={t("extendHATPremium.bannerLinkText")}
            linkOnClick={onOpenExtendPremiumModal}
          />
          <ExtendHATPremiumModal
            isOpen={isExtendPremiumModalOpen}
            handleClose={() => setIsExtendPremiumModalOpen(false)}
            onConfirm={onExtendPremiumConfirm}
          />
        </Box>
      )}
    </Box>
  );
};
