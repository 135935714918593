import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { IconButtonDropdown } from "adminComponents/molecules/IconButtonDropdown";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { pxToRem } from "adminComponents/utils";
import { IGradeLevel, IUser, UserRole, UsersGroupsRole } from "links/lib/types";

interface IProps {
  accuracy?: number;
  handleEditStudent?: (user: IUser) => void;
  handleEditTeacher?: (user: IUser, usersGroupsRole: UsersGroupsRole) => void;
  handleApprove?: (user: IUser) => void;
  handleMoveToDifferentClassroom?: (user: IUser) => void;
  handleReject?: (user: IUser) => void;
  handleRemove?: (user: IUser) => void;
  gradeLevel?: IGradeLevel;
  isApproved?: boolean;
  isPending?: boolean;
  percentCorrect?: number;
  showAccuracy?: boolean;
  user: IUser;
  classroomId: string;
  usersGroupsRole?: UsersGroupsRole;
  flagTeachers?: boolean;
}

export const UserCard: React.FC<IProps> = ({
  accuracy,
  handleEditStudent,
  handleEditTeacher,
  handleApprove,
  handleMoveToDifferentClassroom,
  handleReject,
  handleRemove,
  gradeLevel,
  isApproved = true,
  isPending = false,
  showAccuracy,
  user,
  classroomId,
  usersGroupsRole,
  flagTeachers = false,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const {
    email,
    family_name,
    given_name,
    profile_image_url,
    student_nickname,
    student_season_level,
  } = user;

  const fullName = given_name
    ? given_name + `${family_name ? " " + family_name : ""}`
    : undefined;

  const name = student_nickname
    ? student_nickname
    : fullName
    ? fullName
    : undefined;

  const bottomLine = [];

  if (student_nickname && fullName && student_nickname !== fullName) {
    bottomLine.push(fullName);
  }

  if (name) {
    if (gradeLevel) {
      bottomLine.push(gradeLevel.grade_level);
    }
    if (email) {
      bottomLine.push(email);
    }
  }

  if (usersGroupsRole && usersGroupsRole !== UsersGroupsRole.Member) {
    bottomLine.push(`${t(`common.usersGroupsRole${usersGroupsRole}`)}`);
  } else if (student_season_level) {
    bottomLine.push(`${t("common.level")}${student_season_level}`);
  }

  const styles = useMultiStyleConfig("AdminUserCard", {
    isApproved,
    showAccuracy,
  });

  const handleEditUserClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (handleEditTeacher && usersGroupsRole) {
        handleEditTeacher(user, usersGroupsRole);
      } else {
        handleEditStudent?.(user);
      }
    },
    [handleEditStudent, handleEditTeacher, user, usersGroupsRole]
  );

  const handleRemoveUserClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      handleRemove?.(user);
    },
    [handleRemove, user]
  );

  const handleApproveUserClick = useCallback(() => {
    handleApprove?.(user);
  }, [handleApprove, user]);

  const handleRejectUserClick = useCallback(() => {
    handleReject?.(user);
  }, [handleReject, user]);

  const avatarSize = useBreakpointValue({
    base: "lg",
    md: "xl",
  });

  const userURL = `/t/classrooms/${classroomId}/students/${user.id}`;

  const dropdownMenuItems = [];
  if (handleMoveToDifferentClassroom) {
    dropdownMenuItems.push({
      label: t("student.moveToDifferentClassroom"),
      handleClick: () => {
        handleMoveToDifferentClassroom(user);
      },
    });
  }

  const showTeacherFlag =
    flagTeachers &&
    user.role === UserRole.Teacher &&
    usersGroupsRole === UsersGroupsRole.Member;

  return (
    <LinkBox>
      <Card
        backgroundColor={isApproved ? "primary.white" : "transparent"}
        borderColor="primary.tan"
        sx={styles.container}
        variant={
          isApproved
            ? "adminCardSelectable"
            : "adminCardSmallBorderSmallPadding"
        }
      >
        <Box
          zIndex="10"
          position="absolute"
          right={pxToRem(32)}
          top={pxToRem(10)}
        >
          {isApproved && user.id !== "0" && (
            <IconButtonDropdown
              iconVariant="adminLibraryPracticeSetCard"
              menuItems={dropdownMenuItems}
            />
          )}
        </Box>
        {showTeacherFlag && (
          <Box
            zIndex="10"
            position="absolute"
            right={pxToRem(3)}
            bottom={pxToRem(3)}
            backgroundColor="utility.badge-red"
            borderBottomRightRadius={pxToRem(16)}
            borderTopLeftRadius={pxToRem(8)}
            paddingX={pxToRem(8)}
            paddingY={pxToRem(4)}
          >
            <HStack>
              <Text>{t("common.teacher")}</Text>
              <IconTooltip triggerEl={<QuestionIcon color="utility.link" />}>
                <Trans i18nKey="student.teacherWarning" t={t}>
                  <Link
                    target="_blank"
                    color="utility.link"
                    href="https://support.giantsteps.app/s/article/Change-Account-from-Teacher-to-Student"
                  >
                    these instructions.
                  </Link>
                </Trans>
              </IconTooltip>
            </HStack>
          </Box>
        )}
        <Box __css={styles.left}>
          <Box __css={styles.avatar}>
            <Avatar
              name={name || ""}
              size={avatarSize}
              src={profile_image_url || ""}
            />
            {isApproved && usersGroupsRole === UsersGroupsRole.Member ? (
              <LinkOverlay as={RouterLink} to={userURL} sx={styles.linkOverlay}>
                <Text variant="adminP1">{name || email}</Text>
              </LinkOverlay>
            ) : (
              <Text variant="adminP1">{name || email}</Text>
            )}
          </Box>
          <Box __css={styles.content}>
            {isApproved && usersGroupsRole === UsersGroupsRole.Member ? (
              <LinkOverlay as={RouterLink} to={userURL} sx={styles.linkOverlay}>
                <Text variant="adminP1">{name || email}</Text>
              </LinkOverlay>
            ) : (
              <Text variant="adminP1">{name || email}</Text>
            )}
            {!isPending && (
              <Text
                color="primary.warm-black"
                variant="adminP2"
                sx={styles.bottomLine}
              >
                {bottomLine.join(" • ")}
              </Text>
            )}
          </Box>
        </Box>
        {(showAccuracy || !isApproved) && !isPending && (
          <Divider __css={styles.divider} />
        )}
        {/*
        if the user is NOT approved we show approve/reject buttons
        if the user approved and we WANT to show accuracy then we show it or else nothing
      */}
        <Box __css={styles.right}>
          {isApproved && (
            <Box __css={styles.accuracyEditContainer}>
              {showAccuracy && user.id !== "0" && (
                <PieChartWithLabel
                  percentage={accuracy}
                  text={t("student.accuracy")}
                />
              )}
              {user.id === "0" && (
                <Box __css={styles.container}>
                  <Text variant="adminP1">{t("student.neverSignedIn")}</Text>
                </Box>
              )}
              {(handleEditStudent || handleEditTeacher) && (
                <Box __css={styles.editButtonContainer}>
                  <IconButtonWithTooltip
                    icon="edit_outlined"
                    shape="type1"
                    variant="ghost"
                    onClick={handleEditUserClick}
                    ariaLabel={t("common.edit")}
                    tooltipProps={{ popoverVariant: "default" }}
                  />
                </Box>
              )}
              {handleRemove && (
                <Box __css={styles.editButtonContainer}>
                  <IconButtonWithTooltip
                    icon="delete_outlined"
                    shape="type1"
                    variant="ghost"
                    onClick={handleRemoveUserClick}
                    ariaLabel={t("common.leaveClassroom")}
                    tooltipProps={{ popoverVariant: "default" }}
                  />
                </Box>
              )}
            </Box>
          )}
          {!isApproved && isPending && (
            <Box __css={styles.buttonContainer}>
              <Button
                onClick={handleApproveUserClick}
                size="md"
                variant="adminButtonFilled"
              >
                {t("student.approve")}
              </Button>
              <Button
                onClick={handleRejectUserClick}
                size="md"
                variant="adminButtonOutlined"
              >
                {t("student.reject")}
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </LinkBox>
  );
};
